import React from 'react'
import styled from 'styled-components'
import { breakpoints, fontFamily, rem, springColor } from 'variables/_index'
import UpImage from 'assets/images/spring/voice/card-up.svg'

export interface VoiceCardProps {
  readonly noProps?: string
  readonly score: string
  readonly subject: string
  readonly imageElement: JSX.Element
  readonly grade: string
  readonly name: string
  readonly text: JSX.Element
}

type VoiceCardPropsClassName = VoiceCardProps & {
  readonly className: string
}

function Component({ className, score, subject, imageElement, grade, name, text }: VoiceCardPropsClassName) {
  return (
    <div className={className}>
      <div className="e-subject">{subject}</div>
      <div className="e-title">
        <span className="e-score">
          <span className="e-number">{score}</span>点
        </span>
        アップ!
      </div>
      <div className="e-meta">
        <div className="e-grade">{grade}</div>
        <div className="e-name">{name}</div>
      </div>
      <div className="e-image">{imageElement}</div>
      <div className="e-text-area">
        <div className="e-text">{text}</div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<VoiceCardPropsClassName>`
  /* height: ${rem(624 / 2)}; */
  background-color: #fff;
  border-radius: ${rem(30 / 2)};
  position: relative;
  padding: ${rem(22 / 2)} ${rem(32 / 2)} ${rem(26 / 2)};
  text-align: center;
  box-shadow: 0 ${rem(10 / 2)} 0 #039ec9;

  @media ${breakpoints.lg} {
    border-radius: ${rem(30)};
    border-width: 3px;
    padding: ${rem(18)} ${rem(38)} ${rem(30)};
    box-shadow: 0 ${rem(10)} 0 #039ec9;
  }

  > .e-subject {
    position: absolute;
    top: ${rem(40 / 2)};
    left: ${rem(-12 / 2)};
    background: ${springColor.accent2};
    color: #fff;
    font-weight: 700;
    font-size: ${rem(32 / 2)};
    line-height: calc(48 / 32);
    text-align: center;
    min-width: ${rem(122 / 2)};
    min-height: ${rem(56 / 2)};
    display: flex;
    align-items: center;
    justify-content: center;

    @media ${breakpoints.lg} {
      top: ${rem(34)};
      left: ${rem(-10)};
      font-size: ${rem(24)};
      min-width: ${rem(98)};
    }
  }

  > .e-title {
    font-size: ${rem(50 / 2)};
    font-weight: 700;
    text-align: center;
    line-height: 1.2;
    padding-left: ${rem(88 / 2)};
    color: ${springColor.accent2};
    letter-spacing: 0.02em;
    position: relative;
    width: fit-content;

    @media ${breakpoints.lg} {
      font-size: ${rem(40)};
      padding-left: ${rem(78)};
    }

    .e-score {
      color: #ff1a56;
    }

    .e-number {
      font-size: ${rem(68 / 2)};
      font-family: ${fontFamily.montserrat};

      @media ${breakpoints.lg} {
        font-size: ${rem(54)};
      }
    }

    &::after {
      content: '';
      position: absolute;
      width: ${rem(42.24 / 2)};
      height: ${rem(42.24 / 2)};
      background-image: url(${UpImage});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      bottom: ${rem(4)};
      left: calc(100% + ${rem(10 / 2)});

      @media ${breakpoints.lg} {
        width: ${rem(42.24)};
        height: ${rem(42.24)};
        bottom: ${rem(4)};
        left: calc(100% + ${rem(10)});
      }
    }
  }

  > .e-meta {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: ${rem(10 / 2)};
    font-size: ${rem(28 / 2)};
    letter-spacing: 0.06em;
    color: ${springColor.text};

    @media ${breakpoints.lg} {
      margin-top: ${rem(6)};
      font-size: ${rem(16)};
    }

    > .e-grade {
    }

    > .e-name {
      &::before {
        content: '｜';
      }
    }
  }

  > .e-image {
    margin-top: ${rem(14 / 2)};
    text-align: center;

    @media ${breakpoints.lg} {
      margin-top: ${rem(16)};
    }

    img {
      width: ${rem(270 / 2)};

      @media ${breakpoints.lg} {
        width: ${rem(240)};
      }
    }
  }

  > .e-text-area {
    margin-top: ${rem(24 / 2)};
    text-align: left;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;

    @media ${breakpoints.lg} {
      margin-top: ${rem(16)};
    }

    > .e-text {
      display: inline;
      line-height: calc(48 / 32);
      font-size: ${rem(32 / 2)};
      font-weight: 700;
      color: #000;
      background: linear-gradient(transparent 80%, ${springColor.springMainBackground} 0);

      @media ${breakpoints.lg} {
        font-size: ${rem(24)};
        line-height: calc(38 / 24);
      }
    }
  }
`

export const VoiceCard: (props: VoiceCardProps) => JSX.Element = StyledComponent

export default VoiceCard
