import React from 'react'
import Layout from 'layout/base'
import Seo from 'components/seo'
import { SpringTemplate } from 'templates/springTemplate'
import type { TypeBreadcrumb } from 'types/breadcrumb'
import { siteStructure } from 'utils/site'

interface SpringPageProps {
  pageContext: TypeBreadcrumb
  location: Location
}

const WinterPage = ({ pageContext, location }: SpringPageProps): JSX.Element => {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  // TODO : 全ページで作っているので共通化したい
  const breadcrumbItems = crumbs.map(crumb => {
    return crumb.crumbLabel in siteStructure
      ? {
          ...crumb,
          ...{ crumbLabel: siteStructure[crumb.crumbLabel]?.name },
        }
      : { ...crumb }
  })

  return (
    <Layout pageType="springLP" breadcrumbItems={breadcrumbItems} isDisplayCTASection={false} ctaType="type2">
      <Seo
        title="春期講習2024-オンライン個別指導塾Fit NET STUDY"
        description="講師が直接教えるオンライン個別指導の春期講習。「わからない」をなくして、新学年で点数アップ！4日間の講習授業を無料で体験できます。小学生・中学生・高校生対象。"
        path={location.pathname}
      />
      <SpringTemplate />
    </Layout>
  )
}

export default WinterPage
