import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { rem, fontFamily, breakpoints } from 'variables/_index'
import ContactSection from 'components/section/spring/contactSection'
import { Pagination, Navigation, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css/autoplay'
import TeacherCard from '../atom/teacherCard'
import ArrowNextImageSP from 'assets/images/spring/course/teacher/slide-right.svg'
import ArrowPrevImageSP from 'assets/images/spring/course/teacher/slide-left.svg'
import ArrowNextImagePC from 'assets/images/spring/course/teacher/slide-right.svg'
import ArrowPrevImagePC from 'assets/images/spring/course/teacher/slide-left.svg'
import { useInView } from 'react-intersection-observer'

export interface CourseJuniorHighSchoolProps {
  readonly noProps?: string
}

type CourseJuniorHighSchoolPropsClassName = CourseJuniorHighSchoolProps & {
  readonly className: string
}

function Component({ className }: CourseJuniorHighSchoolPropsClassName) {
  const { ref, inView } = useInView({
    rootMargin: '-100px', // ref要素が現れてから100px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  })

  const { ref: ref2, inView: inView2 } = useInView({
    rootMargin: '-100px',
    triggerOnce: true,
  })

  return (
    <div className={className}>
      <div className="e-course">
        <h3 className="e-course-head">中学生コース</h3>

        <div className="e-course-img">
          <StaticImage src="../../../../assets/images/spring/course/junior/course-junior-img.png" alt="" placeholder="none" />
        </div>

        <a href="#junior-overview" className="e-course-link">
          日程や料金はこちら
          <span className="e-course-link-arrow">
            <svg xmlns="http://www.w3.org/2000/svg" width="14.93" height="25.4" viewBox="0 0 14.93 25.4">
              <path
                id="course_btn_arrow"
                d="M15.049,10.609a2.158,2.158,0,0,1,3.041,0L28.717,21.274a2.149,2.149,0,0,1,.067,2.964L18.313,34.747a2.147,2.147,0,1,1-3.041-3.031l8.9-9.038-9.123-9.038A2.126,2.126,0,0,1,15.049,10.609Z"
                transform="translate(-14.414 -9.982)"
                fill="#0028be"
              />
            </svg>
          </span>
        </a>

        <div className="e-course-point">
          <h4 ref={ref} className="e-course-point-head" data-anim={inView}>
            <span className="e-course-point-illust1">
              <StaticImage src="../../../../assets/images/spring/course/ill_book.png" alt="" placeholder="none" />
            </span>
            春期講習の
            <br />
            <span className="e-course-point-head-highlight">
              <span className="e-course-point-head-big">2</span>つ
            </span>
            <span className="e-course-point-head-middle">のポイント</span>
            <span className="e-course-point-illust2">
              <StaticImage src="../../../../assets/images/spring/course/ill_pen.png" alt="" placeholder="none" />
            </span>
          </h4>

          <ul className="e-course-point-list">
            <li className="e-course-point-list-item">
              <span className="e-course-point-list-item-number">1.</span>点数があがるしくみ
            </li>
            <li className="e-course-point-list-item">
              <span className="e-course-point-list-item-number">2.</span>つまずかないサポート
            </li>
          </ul>
          <h5 className="e-course-point-item-head">
            <span className="e-course-point-item-head-badge">
              Point
              <br />
              <span className="e-course-point-item-head-number">1</span>
            </span>
            点数があがるしくみ
          </h5>
          <p className="e-course-point-item-text point-item-text1">
            <span className="e-course-point-item-text-highlight">中学入学時から今までに学習してきた範囲を総復習！</span>
            苦手を見つけ、苦手克服サイクルで「わかった!」に変えます。
          </p>
          <div className="e-course-point-structure">
            <p className="e-course-point-cycle-text">(苦手克服サイクル)</p>

            <div className="e-course-point-cycle-image">
              <StaticImage src="../../../../assets/images/spring/course/point-1-cycle.png" alt="" placeholder="none" />
            </div>

            <div className="e-course-point-arrow arrow-1">
              <StaticImage src="../../../../assets/images/spring/course/course_point_arrow.png" alt="" placeholder="none" />
            </div>

            <p className="e-course-point-item-text point-item-text2">
              <span className="e-course-point-item-text-highlight">苦手克服サイクルをくり返すことで苦手がなくなり</span>
              基礎学力が身につく!
            </p>

            <div className="e-course-point-arrow arrow-2">
              <StaticImage src="../../../../assets/images/spring/course/course_point_arrow.png" alt="" placeholder="none" />
            </div>

            <p className="e-course-point-item-text point-item-text3">
              <span className="e-course-point-item-text-highlight">新学年の学校の授業がよくわかる</span>
              ようになり、
            </p>

            <div className="e-course-point-image">
              <StaticImage
                className="u-hidden-pc"
                src="../../../../assets/images/spring/course/junior/course_chu_photo_sp.png"
                alt=""
                placeholder="none"
              />
              <StaticImage
                className="u-hidden-sp"
                src="../../../../assets/images/spring/course/junior/course_chu_photo_pc.png"
                alt=""
                placeholder="none"
              />
            </div>

            <p className="e-course-poin-notes">&#8251;新中学1年生は予習を行います。</p>
          </div>
          <div className="e-course-point-more">
            <span className="e-course-point-more-head">さらに</span>

            <ul className="e-course-point-more-list">
              <li className="e-course-point-more-item1">
                <span className="e-course-point-more-img1">
                  <StaticImage src="../../../../assets/images/spring/course/course-more-illust1.svg" alt="" placeholder="none" />
                </span>
                自宅でも集中が続くかな
              </li>
              <li className="e-course-point-more-item2">
                うちの子にもできるかな？
                <span className="e-course-point-more-img2">
                  <StaticImage src="../../../../assets/images/spring/course/course-more-illust2.svg" alt="" placeholder="none" />
                </span>
              </li>
            </ul>

            <p className="e-course-point-more-text">という不安は</p>

            <p className="e-course-point-more-lead">
              Fitオリジナル
              <br />
              学習システムが解決！
            </p>

            <div className="e-course-point-more-image">
              <StaticImage src="../../../../assets/images/spring/course/course_more_photo.png" alt="" placeholder="none" />
            </div>

            <p className="e-course-point-item-text more-text">
              システムによって講師はお子さまの理解状況をリアルタイムで把握できます。
              <span className="e-course-point-item-text-highlight">一人で悩むことなく適切な解説を受けることができる</span>
              ので苦手がどんどん「わかった」に変わります。
            </p>
          </div>

          <h5 className="e-course-point-item-head" data-type="point2">
            <span className="e-course-point-item-head-badge">
              Point
              <br />
              <span className="e-course-point-item-head-number">2</span>
            </span>
            つまずかないサポート
          </h5>

          <h6 className="e-course-pointe-item-sub-head sub-head1">
            <span className="e-course-pointe-item-sub-head-deco" data-type="left"></span>
            <span className="e-course-pointe-item-sub-head-text">お子さまの専属チーム</span>
            <span className="e-course-pointe-item-sub-head-deco" data-type="right"></span>
          </h6>

          <p className="e-course-point-item-text point-item-text4">
            <span className="e-course-point-item-text-highlight">指導経験豊富な担任と講師が、適切な講習の提案や学習アドバイスを行います。</span>
            常にお子さまに寄り添う指導が、勉強のやる気アップにもつながります。
          </p>

          <p className="e-course-teacher-head">実際の講師を一部紹介！</p>

          <div className="e-slide">
            <div className="e-slide-body">
              <Swiper
                modules={[Pagination, Navigation, Autoplay]}
                spaceBetween={12.5}
                slidesPerView={2}
                centeredSlides={false}
                pagination={{ type: 'fraction' }}
                navigation={true}
                loop={true}
                observer={true}
                observeParents={true}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                loopAdditionalSlides={1}
                breakpoints={{
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                    centeredSlides: false,
                    loopAdditionalSlides: 0,
                  },
                }}
              >
                <SwiperSlide>
                  <TeacherCard
                    title="榎本先生"
                    imageElement={
                      <StaticImage
                        className="e-image"
                        src="../../../../assets/images/spring/course/teacher/teacher01.png"
                        alt=""
                        placeholder="none"
                      />
                    }
                    position="神戸大学在籍"
                    text={<>生徒一人ひとりに寄り添って教えていきます!</>}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <TeacherCard
                    title="志賀先生"
                    imageElement={
                      <StaticImage
                        className="e-image"
                        src="../../../../assets/images/spring/course/teacher/teacher02.png"
                        alt=""
                        placeholder="none"
                      />
                    }
                    position="九州大学在籍"
                    text={<>楽しく学べるよう全力でサポートします!</>}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <TeacherCard
                    title="田中先生"
                    imageElement={
                      <StaticImage
                        className="e-image"
                        src="../../../../assets/images/spring/course/teacher/teacher03.png"
                        alt=""
                        placeholder="none"
                      />
                    }
                    position="京都大学在籍"
                    text={<>わかるまで丁寧に解説します!</>}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <TeacherCard
                    title="横井先生"
                    imageElement={
                      <StaticImage
                        className="e-image"
                        src="../../../../assets/images/spring/course/teacher/teacher04.png"
                        alt=""
                        placeholder="none"
                      />
                    }
                    position="名古屋大学在籍"
                    text={<>分かりやすく、噛み砕いて説明します!</>}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <TeacherCard
                    title="岸本先生"
                    imageElement={
                      <StaticImage
                        className="e-image"
                        src="../../../../assets/images/spring/course/teacher/teacher05.png"
                        alt=""
                        placeholder="none"
                      />
                    }
                    position="神戸大学在籍"
                    text={<>楽しい授業を目標にしています!</>}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <TeacherCard
                    title="田村先生"
                    imageElement={
                      <StaticImage
                        className="e-image"
                        src="../../../../assets/images/spring/course/teacher/teacher06.png"
                        alt=""
                        placeholder="none"
                      />
                    }
                    position="名古屋大学在籍"
                    text={<>親しみやすい穏和な先生を目指しています!</>}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <TeacherCard
                    title="日下先生"
                    imageElement={
                      <StaticImage
                        className="e-image"
                        src="../../../../assets/images/spring/course/teacher/teacher07.png"
                        alt=""
                        placeholder="none"
                      />
                    }
                    position="金沢医科大学在籍"
                    text={<>夢を叶えるために一緒に頑張りましょう!</>}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <TeacherCard
                    title="村上先生"
                    imageElement={
                      <StaticImage
                        className="e-image"
                        src="../../../../assets/images/spring/course/teacher/teacher08.png"
                        alt=""
                        placeholder="none"
                      />
                    }
                    position="名古屋市立大学在籍"
                    text={<>一緒に頑張りましょう!</>}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <TeacherCard
                    title="小松先生"
                    imageElement={
                      <StaticImage
                        className="e-image"
                        src="../../../../assets/images/spring/course/teacher/teacher09.png"
                        alt=""
                        placeholder="none"
                      />
                    }
                    position="東京工業大学在籍"
                    text={<>丁寧にわかりやすく教えます!</>}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <TeacherCard
                    title="中川先生"
                    imageElement={
                      <StaticImage
                        className="e-image"
                        src="../../../../assets/images/spring/course/teacher/teacher10.png"
                        alt=""
                        placeholder="none"
                      />
                    }
                    position="埼玉大学在籍"
                    text={<>楽しく一緒に勉強しましょう!</>}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <TeacherCard
                    title="入鹿先生"
                    imageElement={
                      <StaticImage
                        className="e-image"
                        src="../../../../assets/images/spring/course/teacher/teacher11.png"
                        alt=""
                        placeholder="none"
                      />
                    }
                    position="広島大学在籍"
                    text={<>楽しい授業できる様に頑張っています!</>}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <TeacherCard
                    title="中川先生"
                    imageElement={
                      <StaticImage
                        className="e-image"
                        src="../../../../assets/images/spring/course/teacher/teacher12.png"
                        alt=""
                        placeholder="none"
                      />
                    }
                    position="九州大学在籍"
                    text={<>しっかり解説します、一緒に頑張りましょう!</>}
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>

          <div className="e-online">
            <div className="e-online-top">
              <div className="e-online-top-image">
                <StaticImage src="../../../../assets/images/spring/course/ill_fusen.png" alt="" placeholder="none" />
              </div>
            </div>

            <h6 className="e-course-pointe-item-sub-head sub-head2" data-type="online">
              <span className="e-course-pointe-item-sub-head-deco" data-type="left"></span>
              <span className="e-course-pointe-item-sub-head-text">オンライン自習室</span>
              <span className="e-course-pointe-item-sub-head-deco" data-type="right"></span>
            </h6>

            <div className="e-online-image">
              <StaticImage src="../../../../assets/images/spring/course/course_online_photo.png" alt="" placeholder="none" />
            </div>

            <p className="e-course-point-item-text point-item-text5">
              宿題など授業以外の学習をするときも、
              <span className="e-course-point-item-text-highlight">勉強を頑張る生徒がいる環境で勉強できるので、集中して取り組むことができます。</span>
            </p>
          </div>
        </div>

        <div className="e-cta1">
          <ContactSection
            type="junior"
            balloonImageElement={<StaticImage src="../assets/images/illustration/illustration16-2.png" alt="" placeholder="none" />}
            text={false}
          />
        </div>

        <div id="junior-overview" className="e-course-overview">
          <h2 ref={ref2} className="e-course-overview-head" data-anim={inView2}>
            <span className="e-course-overview-head-ill1">
              <StaticImage src="../../../../assets/images/spring/course/ill_clock.png" alt="" placeholder="none" />
            </span>
            春期講習の<span className="e-course-overview-head-big">概要</span>
            <span className="e-course-overview-head-ill2">
              <StaticImage src="../../../../assets/images/spring/course/ill_note.png" alt="" placeholder="none" />
            </span>
          </h2>

          <div className="e-course-overview-item overview-top">
            <h3 className="e-course-overview-item-head">対象</h3>

            <div className="e-course-overview-contents">
              <p className="e-course-overview-top-text">新中学1年生・新中学2年生・新中学3年生</p>
            </div>
          </div>

          <div className="e-course-overview-item">
            <h3 className="e-course-overview-item-head">日程と時間割</h3>

            <div className="e-course-overview-contents">
              <div className="e-course-overview-inner">
                <p className="e-course-overview-time-text">
                  <span className="text-highlight-bold">❶日程・❷時間割・❸教科</span>
                  の中から、<span className="text-underline">自由にスケジュールを選んで受講</span>できます。
                </p>

                <div className="e-course-overview-schedule">
                  <h4 className="e-course-overview-time-head">
                    <span>
                      <span className="e-course-overview-time-head-highlight">❶日程</span>を選ぶ
                    </span>
                  </h4>

                  <ul className="e-course-overview-schedule-list">
                    <li className="e-course-overview-schedule-list-item">
                      <span className="e-course-overview-schedule-list-item-icon">
                        <StaticImage src="../../../../assets/images/spring/course/overview-check.png" alt="" placeholder="none" />
                      </span>
                      A日程｜3/18(月)～3/21(木)
                    </li>
                    <li className="e-course-overview-schedule-list-item">
                      <span className="e-course-overview-schedule-list-item-icon">
                        <StaticImage src="../../../../assets/images/spring/course/overview-check.png" alt="" placeholder="none" />
                      </span>
                      B日程｜3/22(金)・23(土)・25(月)・26(火)
                    </li>
                    <li className="e-course-overview-schedule-list-item">
                      <span className="e-course-overview-schedule-list-item-icon">
                        <StaticImage src="../../../../assets/images/spring/course/overview-check.png" alt="" placeholder="none" />
                      </span>
                      C日程｜3/27(水)～3/30(土)
                    </li>
                    <li className="e-course-overview-schedule-list-item">
                      <span className="e-course-overview-schedule-list-item-icon">
                        <StaticImage src="../../../../assets/images/spring/course/overview-check.png" alt="" placeholder="none" />
                      </span>
                      D日程｜4/1(月)～4/4(木)
                    </li>
                  </ul>
                </div>

                <div className="e-course-overview-arrow">
                  <StaticImage src="../../../../assets/images/spring/course/overview-arrow.png" alt="" placeholder="none" />
                </div>

                <div className="e-course-overview-timetable">
                  <h4 className="e-course-overview-time-head">
                    <span>
                      <span className="e-course-overview-time-head-highlight">❷時間割</span>を選ぶ
                    </span>
                  </h4>

                  <ul className="e-course-overview-timetable-list">
                    <li className="e-course-overview-timetable-list-item">
                      <span className="e-course-overview-timetable-list-item-icon">
                        <StaticImage src="../../../../assets/images/spring/course/overview-check.png" alt="" placeholder="none" />
                      </span>
                      1時間目｜14：30〜15：50
                    </li>
                    <li className="e-course-overview-timetable-list-item">
                      <span className="e-course-overview-timetable-list-item-icon">
                        <StaticImage src="../../../../assets/images/spring/course/overview-check.png" alt="" placeholder="none" />
                      </span>
                      2時間目｜16：00〜17：20
                    </li>
                    <li className="e-course-overview-timetable-list-item">
                      <span className="e-course-overview-timetable-list-item-icon">
                        <StaticImage src="../../../../assets/images/spring/course/overview-check.png" alt="" placeholder="none" />
                      </span>
                      3時間目｜17：30〜18：50
                    </li>
                    <li className="e-course-overview-timetable-list-item">
                      <span className="e-course-overview-timetable-list-item-icon">
                        <StaticImage src="../../../../assets/images/spring/course/overview-check.png" alt="" placeholder="none" />
                      </span>
                      4時間目｜19：00〜20：20
                    </li>
                    <li className="e-course-overview-timetable-list-item">
                      <span className="e-course-overview-timetable-list-item-icon">
                        <StaticImage src="../../../../assets/images/spring/course/overview-check.png" alt="" placeholder="none" />
                      </span>
                      5時間目｜20：30〜21：50
                    </li>
                  </ul>
                </div>

                <div className="e-course-overview-arrow">
                  <StaticImage src="../../../../assets/images/spring/course/overview-arrow.png" alt="" placeholder="none" />
                </div>

                <div className="e-course-overview-subject">
                  <h4 className="e-course-overview-time-head">
                    <span>
                      <span className="e-course-overview-time-head-highlight">❸教科</span>を選ぶ
                    </span>
                  </h4>

                  <ul className="e-course-overview-subject-list">
                    <li className="e-course-overview-subject-list-item">
                      <span className="e-course-overview-subject-list-item-icon">
                        <StaticImage src="../../../../assets/images/spring/course/overview-check.png" alt="" placeholder="none" />
                      </span>
                      数学
                    </li>
                    <li className="e-course-overview-subject-list-item">
                      <span className="e-course-overview-subject-list-item-icon">
                        <StaticImage src="../../../../assets/images/spring/course/overview-check.png" alt="" placeholder="none" />
                      </span>
                      英語
                    </li>
                    <li className="e-course-overview-subject-list-item">
                      <span className="e-course-overview-subject-list-item-icon">
                        <StaticImage src="../../../../assets/images/spring/course/overview-check.png" alt="" placeholder="none" />
                      </span>
                      国語
                    </li>
                    <li className="e-course-overview-subject-list-item">
                      <span className="e-course-overview-subject-list-item-icon">
                        <StaticImage src="../../../../assets/images/spring/course/overview-check.png" alt="" placeholder="none" />
                      </span>
                      理科
                    </li>
                    <li className="e-course-overview-subject-list-item">
                      <span className="e-course-overview-subject-list-item-icon">
                        <StaticImage src="../../../../assets/images/spring/course/overview-check.png" alt="" placeholder="none" />
                      </span>
                      社会
                    </li>
                  </ul>
                </div>

                <div className="e-course-overview-example">
                  <h5 className="e-course-overview-example-head">受講例</h5>

                  <span className="e-course-overview-example-contents">
                    <span className="e-course-overview-example-icon-text">
                      <span className="e-course-overview-example-icon">
                        <StaticImage src="../../../../assets/images/spring/course/overview-example.png" alt="" placeholder="none" />
                      </span>
                      <p className="e-course-overview-example-text">中学2年生のAさんの場合・・・</p>
                    </span>
                    <p className="e-course-overview-example-text">
                      <span className="text-highlight-bold">A日程</span>の<span className="text-highlight-bold">3時間目</span>に
                      <span className="text-highlight-bold">数学</span>を受講しています。
                    </p>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="e-course-overview-item">
            <h3 className="e-course-overview-item-head">授業形式</h3>

            <div className="e-course-overview-contents">
              <div className="e-course-overview-inner">
                <div className="e-course-overview-format">
                  <p className="e-course-overview-format-text">1回｜80分授業</p>

                  <ul className="e-course-overview-format-list">
                    <li className="e-course-overview-format-list-item">数英国 ｜講師1人 対 生徒2人</li>
                    <li className="e-course-overview-format-list-item">理　社 ｜講師1人 対 生徒8人</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="e-course-overview-item">
            <h3 className="e-course-overview-item-head">料金</h3>

            <div className="e-course-overview-contents">
              <div className="e-course-overview-inner">
                <ul className="e-course-overview-price-list">
                  <li className="e-course-overview-price-list-item">
                    <span className="e-course-overview-price-item-text">
                      4コマ ｜<span className="text-highlight-bold">無料</span>
                    </span>
                  </li>
                  <li className="e-course-overview-price-list-item">
                    <span className="e-course-overview-price-item-text">
                      8コマ ｜<span className="text-bold">5,900円</span>
                    </span>
                  </li>
                  <li className="e-course-overview-price-list-item">
                    <span className="e-course-overview-price-item-text">
                      12コマ｜<span className="text-bold">11,800円</span>
                    </span>
                  </li>
                </ul>

                <ul className="e-course-overview-price-list-notes">
                  <li className="e-course-overview-price-list-notes-item">&#8251;表示価格は税込みです。</li>
                  <li className="e-course-overview-price-list-notes-item">
                    &#8251;テキスト料金は別途かかります。
                    <br className="is-sp" />
                    2,400円/冊　送料／2冊まで:800円 3冊以上:1,000円
                  </li>
                  <li className="e-course-overview-price-list-notes-item">&#8251;初めて受講される場合のみ無料で受講いただけます。</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="e-course-overview-close">
            <p className="e-course-overview-close-text">最終申し込み締切日</p>
            <div className="e-course-overview-close-arrow">
              <StaticImage src="../../../../assets/images/spring/course/close_hukidashi.png" alt="" placeholder="none" />
            </div>
            <p className="e-course-overview-close-date">3/21(木)</p>

            <ul className="e-course-overview-close-notes">
              <li className="e-course-overview-close-notes-item">&#8251;満席になり次第、申し込みを締め切る場合があります。</li>
              <li className="e-course-overview-close-notes-item">&#8251;申し込み締切日は各日程によって異なります。</li>
            </ul>
          </div>
        </div>

        <div className="e-cta2">
          <ContactSection
            type="junior"
            balloonImageElement={<StaticImage src="../assets/images/illustration/illustration16-2.png" alt="" placeholder="none" />}
            text={false}
          />
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<CourseJuniorHighSchoolPropsClassName>`
  > .e-course {
    margin-top: ${rem(-2)};
    border-top: ${rem(2)} solid #000;
    background-color: #ade2ff;

    @media ${breakpoints.lg} {
      margin-top: ${rem(-3)};
      border-top: ${rem(3)} solid #000;
    }

    > .e-course-head {
      text-align: center;
      border-top: ${rem(2)} solid #000;
      border-bottom: ${rem(2)} solid #000;
      width: ${rem(370 / 2)};
      margin: ${rem(70 / 2)} auto 0 auto;
      padding: ${rem(10 / 2)} 0;
      color: #000000;
      font-size: ${rem(58 / 2)};
      font-family: ${fontFamily.zenKaku};

      @media ${breakpoints.lg} {
        width: ${rem(293)};
        margin: ${rem(80)} auto 0 auto;
        padding: ${rem(10)} 0;
        border-top: ${rem(3)} solid #000;
        border-bottom: ${rem(3)} solid #000;
        font-size: ${rem(42)};
      }
    }

    > .e-course-img {
      width: ${rem(302 / 2)};
      margin: ${rem(38.5 / 2)} auto 0 auto;

      @media ${breakpoints.lg} {
        width: ${rem(273)};
        margin: ${rem(49.2)} auto 0 auto;
      }
    }

    > .e-course-link {
      position: relative;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      width: ${rem(540 / 2)};
      margin: 0 auto;
      text-decoration: none;
      font-family: ${fontFamily.zenKaku};
      font-weight: bold;
      padding: ${rem(28 / 2)} 0;
      color: #0028be;
      background-color: #fff;
      border-radius: 9999px;
      border: solid#B4D4E8 ${rem(8 / 2)};
      transition: all 0.3s ease-in-out;

      @media ${breakpoints.lg} {
        width: ${rem(432)};
        padding: ${rem(22)} 0;
        border: solid#B4D4E8 ${rem(6)};
        font-size: ${rem(24)};
      }
      &:hover {
        color: #fff;
        background-color: #00c8ff;

        .e-course-link-arrow {
          svg {
            stroke: white;

            path {
              fill: white;
            }
          }
        }
      }

      .e-course-link-arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: ${rem(35 / 2)};
        width: ${rem(14.93 / 2)};
        height: ${rem(25.4 / 2)};

        @media ${breakpoints.lg} {
          right: ${rem(28)};
          width: ${rem(11.94)};
          height: ${rem(20.32)};
        }

        svg {
          width: 100%;
          transition: all 0.3s ease-in-out;

          path {
            transition: all 0.3s ease-in-out;
          }
        }
      }
    }

    > .e-course-point {
      background-color: #fff;
      border-radius: ${rem(20 / 2)};
      width: ${rem(670 / 2)};
      margin: ${rem(97 / 2)} auto 0 auto;
      padding: ${rem(47 / 2)} 0 ${rem(48 / 2)} 0;

      @media ${breakpoints.lg} {
        width: ${rem(1000)};
        margin: ${rem(80)} auto 0 auto;
        padding: ${rem(38.9)} 0 ${rem(57)} 0;
      }

      > .e-course-point-head {
        position: relative;
        width: fit-content;
        margin: 0 auto;
        font-family: ${fontFamily.zenKaku};
        font-size: ${rem(42 / 2)};
        text-align: center;
        color: #0028be;
        text-decoration: underline;
        text-decoration-color: #000;
        text-underline-offset: ${rem(6)};
        letter-spacing: 0.06em;

        &[data-anim='true'] {
          > .e-course-point-illust1 {
            animation: slideFadeIn 1s 0s forwards;
          }

          > .e-course-point-illust2 {
            animation: slideFadeIn 1s 0s forwards;
          }
        }

        @media ${breakpoints.lg} {
          font-size: ${rem(32)};
        }

        > .e-course-point-head-highlight {
          font-size: ${rem(54 / 2)};
          color: #ff1a56;
          letter-spacing: 0.06em;

          @media ${breakpoints.lg} {
            font-size: ${rem(42)};
          }

          > .e-course-point-head-big {
            font-family: ${fontFamily.montserrat};
            font-size: ${rem(68 / 2)};
            letter-spacing: 0.06em;

            @media ${breakpoints.lg} {
              font-size: ${rem(54)};
            }
          }
        }
        > .e-course-point-head-middle {
          font-size: ${rem(54 / 2)};
          letter-spacing: 0.06em;

          @media ${breakpoints.lg} {
            font-size: ${rem(42)};
          }
        }

        > .e-course-point-illust1 {
          position: absolute;
          width: ${rem(130 / 2)};
          top: ${rem(-80 / 2)};
          left: ${rem(-120 / 2)};
          opacity: 0;
          transform: translateY(${rem(20)});

          @media ${breakpoints.lg} {
            width: ${rem(100)};
            top: ${rem(-25)};
            left: ${rem(-128)};
          }
        }

        > .e-course-point-illust2 {
          position: absolute;
          width: ${rem(112.62 / 2)};
          bottom: ${rem(50 / 2)};
          opacity: 0;
          transform: translateY(${rem(20)});

          @media ${breakpoints.lg} {
            width: ${rem(90.1)};
            right: ${rem(-120)};
            bottom: ${rem(15)};
          }
        }
      }

      > .e-course-point-list {
        display: flex;
        flex-direction: column;
        gap: ${rem(30 / 2)};
        list-style: none;
        width: ${rem(560 / 2)};
        margin: ${rem(50 / 2)} auto 0 auto;

        @media ${breakpoints.lg} {
          width: ${rem(900)};
          margin: ${rem(44)} auto 0 auto;
          flex-direction: row;
          gap: ${rem(40)};
        }

        > .e-course-point-list-item {
          color: #000;
          font-size: ${rem(36 / 2)};
          font-family: ${fontFamily.zenKaku};
          font-weight: bold;
          line-height: calc(68 / 36);
          margin: 0;
          padding: ${rem(11 / 2)} ${rem(33 / 2)};
          border-radius: ${rem(10 / 2)};
          background-color: #fff0f0;

          @media ${breakpoints.lg} {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            font-size: ${rem(24)};
            border-radius: ${rem(10)};
          }

          > .e-course-point-list-item-number {
            font-size: ${rem(52 / 2)};
            font-family: ${fontFamily.montserrat};
            font-weight: 600;
            line-height: calc(68 / 52);
            color: #ff1a56;
            margin-right: ${rem(7 / 2)};

            @media ${breakpoints.lg} {
              font-size: ${rem(42)};
              margin-right: ${rem(5 / 8)};
            }
          }
        }
      }

      > .e-course-point-item-head {
        position: relative;
        width: ${rem(620 / 2)};
        margin: ${rem(100 / 2)} auto 0 auto;
        text-align: center;
        color: #0028be;
        background-color: #fff0f0;
        font-size: ${rem(42 / 2)};
        padding: ${rem(40 / 2)} 0 ${rem(30 / 2)} 0;
        line-height: calc(58 / 42);
        font-family: ${fontFamily.zenKaku};
        border-top: solid #000 ${rem(2)};
        border-bottom: solid #000 ${rem(2)};

        &[data-type='point2'] {
          margin-top: ${rem(160 / 2)};

          @media ${breakpoints.lg} {
            margin-top: ${rem(160)};
          }
        }

        @media ${breakpoints.lg} {
          width: ${rem(900)};
          margin: ${rem(89)} auto 0 auto;
          font-size: ${rem(32)};
          padding: ${rem(27)} 0;
          line-height: calc(58 / 32);
          border-top: solid #000 ${rem(3)};
          border-bottom: solid #000 ${rem(3)};
        }

        > .e-course-point-item-head-badge {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: ${rem(26 / 2)};
          position: absolute;
          top: ${rem(-40 / 2)};
          left: ${rem(-43 / 2)};
          width: ${rem(130 / 2)};
          height: ${rem(130 / 2)};
          padding-top: ${rem(16 / 2)};
          font-family: ${fontFamily.montserrat};
          color: #fff;
          background-color: #ff648c;
          border-radius: 9999px;

          @media ${breakpoints.lg} {
            top: ${rem(-19)};
            left: ${rem(199)};
            width: ${rem(90)};
            height: ${rem(90)};
            padding-top: ${rem(0)};
            font-size: ${rem(18)};
          }

          > .e-course-point-item-head-number {
            display: block;
            font-size: ${rem(52 / 2)};
            line-height: 1;

            @media ${breakpoints.lg} {
              font-size: ${rem(36)};
            }
          }
        }
      }

      .e-course-pointe-item-sub-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: ${rem(430 / 2)};
        margin: 0 auto;
        text-align: center;
        font-size: ${rem(36 / 2)};
        color: #0028be;
        font-family: ${fontFamily.zenKaku};
        text-decoration: underline;
        text-decoration-color: #ffe6e6;
        text-underline-offset: ${rem(3)};
        text-decoration-thickness: ${rem(8 / 2)};

        @media ${breakpoints.lg} {
          width: ${rem(336)};
          font-size: ${rem(27)};
          text-decoration-thickness: ${rem(6.4)};
        }

        &[data-type='online'] {
          width: ${rem(335 / 2)};

          @media ${breakpoints.lg} {
            width: ${rem(282)};
          }
        }

        .e-course-pointe-item-sub-head-deco {
          position: relative;
          height: ${rem(60 / 2)};

          @media ${breakpoints.lg} {
            height: ${rem(48)};
          }

          &[data-type='left'] {
            border-left: solid #000 ${rem(2)};

            @media ${breakpoints.lg} {
              border-left: solid #000 ${rem(3)};
            }

            &::before,
            &::after {
              display: block;
              content: '';
              width: ${rem(18 / 2)};
              height: ${rem(2)};
              background-color: #000;
              border-radius: 0 9999px 9999px 0;

              @media ${breakpoints.lg} {
                width: ${rem(15)};
                height: ${rem(3)};
              }
            }
            &::before {
            }
            &::after {
              position: absolute;
              bottom: 0;
            }
          }
          &[data-type='right'] {
            border-right: solid #000 ${rem(2)};

            @media ${breakpoints.lg} {
              border-right: solid #000 ${rem(3)};
            }

            &::before,
            &::after {
              display: block;
              content: '';
              width: ${rem(18 / 2)};
              height: ${rem(2)};
              background-color: #000;
              border-radius: 9999px 0 0 9999px;

              @media ${breakpoints.lg} {
                width: ${rem(15)};
                height: ${rem(3)};
              }
            }
            &::before {
            }
            &::after {
              position: absolute;
              bottom: 0;
            }
          }
        }
      }

      .e-course-point-item-text {
        width: ${rem(560 / 2)};
        margin: 0 auto;
        color: #000;
        font-size: ${rem(32 / 2)};
        font-family: ${fontFamily.zenKaku};
        line-height: calc(58 / 32);
        letter-spacing: 0.06em;

        @media ${breakpoints.lg} {
          width: ${rem(620)};
          font-size: ${rem(18)};
          line-height: calc(32 / 18);
        }

        > .e-course-point-item-text-highlight {
          color: #ff1a56;
        }
      }

      > .e-course-point-structure {
        > .e-course-point-cycle-text {
          margin: ${rem(16 / 2)} 0 0 0;
          text-align: center;
          font-family: ${fontFamily.zenKaku};
          font-weight: 500;
          font-size: ${rem(30 / 2)};

          @media ${breakpoints.lg} {
            margin: ${rem(42)} 0 0 0;
            font-size: ${rem(21)};
          }
        }

        > .e-course-point-cycle-image {
          width: ${rem(510 / 2)};
          margin: ${rem(13.5 / 2)} auto 0 auto;

          @media ${breakpoints.lg} {
            width: ${rem(367.2)};
            margin: ${rem(11.4)} auto 0 auto;
          }
        }

        > .e-course-point-arrow {
          width: ${rem(63.55 / 2)};

          @media ${breakpoints.lg} {
            width: ${rem(50.84)};
          }
        }

        > .e-course-point-image {
          width: ${rem(512 / 2)};
          margin: ${rem(33 / 2)} auto 0 ${rem(100 / 2)};

          @media ${breakpoints.lg} {
            width: ${rem(640.6)};
            margin: 0 auto;
          }
        }

        > .e-course-poin-notes {
          width: ${rem(560 / 2)};
          margin: ${rem(25 / 2)} auto;
          font-size: ${rem(22 / 2)};

          @media ${breakpoints.lg} {
            width: ${rem(620)};
            margin: ${rem(6.9)} auto 0 auto;
            font-size: ${rem(14)};
          }
        }
      }

      > .e-course-point-more {
        position: relative;
        width: ${rem(580 / 2)};
        margin: ${rem(92 / 2)} auto 0 auto;
        padding: ${rem(79 / 2)} 0 ${rem(32 / 2)} 0;
        border: solid #00c8ff ${rem(2)};
        border-radius: ${rem(20 / 2)};

        @media ${breakpoints.lg} {
          width: ${rem(700)};
          margin: ${rem(83)} auto 0 auto;
          padding: ${rem(79)} 0 ${rem(81.8)} 0;
          border: solid #00c8ff ${rem(3)};
          border-radius: ${rem(20)};
        }

        > .e-course-point-more-head {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          position: absolute;
          width: ${rem(200 / 2)};
          font-size: ${rem(38 / 2)};
          font-family: ${fontFamily.zenKaku};
          font-weight: bold;
          top: ${rem(-30 / 2)};
          left: 50%;
          transform: translateX(-50%);
          height: ${rem(60 / 2)};
          color: #fff;
          border-radius: 9999px;
          background-color: #00c8ff;
          letter-spacing: 0.1em;

          @media ${breakpoints.lg} {
            width: ${rem(160)};
            top: ${rem(-24)};
            height: ${rem(48)};
            font-size: ${rem(29)};
          }
        }

        > .e-course-point-more-list {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: ${rem(15 / 2)};
          width: ${rem(427 / 2)};
          list-style: none;
          margin: 0 auto;

          @media ${breakpoints.lg} {
            width: ${rem(341.6)};
            gap: ${rem(30)};
          }

          > .e-course-point-more-item1,
          > .e-course-point-more-item2 {
            width: 100%;
            margin: 0 auto;
            padding: ${rem(10 / 2)} 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #ddf3ff;
            border-radius: ${rem(10 / 2)};
            font-size: ${rem(32 / 2)};
            font-weight: bold;

            @media ${breakpoints.lg} {
              padding: ${rem(10)} 0;
              font-size: ${rem(24)};
            }
          }

          > .e-course-point-more-item1 {
            position: relative;
            width: ${rem(404 / 2)};

            @media ${breakpoints.lg} {
              width: ${rem(323.2)};
            }

            > .e-course-point-more-img1 {
              position: absolute;
              top: ${rem(-24 / 2)};
              left: ${rem(-48 / 2)};
              width: ${rem(45.285 / 2)};
              height: ${rem(44.685 / 2)};

              @media ${breakpoints.lg} {
                top: ${rem(-24)};
                left: ${rem(-48)};
                width: ${rem(45.285)};
                height: ${rem(44.685)};
              }
            }
          }

          > .e-course-point-more-item2 {
            position: relative;
            width: ${rem(427 / 2)};

            @media ${breakpoints.lg} {
              width: ${rem(341.6)};
            }

            > .e-course-point-more-img2 {
              position: absolute;
              top: ${rem(-40 / 2)};
              right: ${rem(-48 / 2)};
              width: ${rem(45.285 / 2)};
              height: ${rem(44.685 / 2)};

              @media ${breakpoints.lg} {
                top: ${rem(-40)};
                right: ${rem(-48)};
                width: ${rem(45.285)};
                height: ${rem(44.685)};
              }
            }
          }
        }

        > .e-course-point-more-text {
          text-align: center;
          font-size: ${rem(32 / 2)};
          margin: ${rem(42 / 2)} 0 0 0;

          @media ${breakpoints.lg} {
            font-size: ${rem(23)};
            margin: ${rem(35)} 0 0 0;
          }
        }

        > .e-course-point-more-lead {
          margin: ${rem(41 / 2)} 0 0 0;
          text-align: center;
          font-size: ${rem(42 / 2)};
          font-weight: bold;
          color: #0028be;
          line-height: calc(68 / 42);
          text-decoration: underline;
          text-decoration-color: #000;
          text-underline-offset: ${rem(6)};
          letter-spacing: 0.06em;

          @media ${breakpoints.lg} {
            margin: ${rem(35)} 0 0 0;
            font-size: ${rem(32)};
            line-height: calc(58 / 32);
          }
        }

        > .e-course-point-more-image {
          width: ${rem(410 / 2)};
          margin: ${rem(42 / 2)} auto 0 auto;

          @media ${breakpoints.lg} {
            width: ${rem(328)};
            margin: ${rem(35)} auto 0 auto;
          }
        }
      }

      > .e-course-teacher-head {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: ${rem(418 / 2)};
        text-align: center;
        font-size: ${rem(36 / 2)};
        font-weight: bold;
        color: #0028be;
        margin: ${rem(50 / 2)} auto 0 auto;

        @media ${breakpoints.lg} {
          width: ${rem(324)};
          font-size: ${rem(28)};
          margin-top: ${rem(36)};
        }

        &:before,
        &:after {
          position: absolute;
          display: block;
          content: '';
          width: ${rem(3 / 2)};
          height: ${rem(40 / 2)};
          background-color: #000;

          @media ${breakpoints.lg} {
            width: ${rem(3)};
            height: ${rem(32)};
          }
        }

        &:before {
          left: ${rem(-3 / 2)};
          transform: rotate(-20deg);

          @media ${breakpoints.lg} {
            left: ${rem(-8)};
          }
        }

        &:after {
          right: ${rem(-3 / 2)};
          transform: rotate(20deg);

          @media ${breakpoints.lg} {
            right: ${rem(-8)};
          }
        }
      }

      .e-slide {
        margin: ${rem(40)} auto 0;

        @media ${breakpoints.lg} {
          margin-top: ${rem(36)};
          width: ${rem(900)};
          overflow: hidden;
        }

        > .e-slide-head {
          text-align: center;

          > .e-slide-head-pop {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: ${rem(18)};
            font-weight: 700;
            font-family: ${fontFamily.zenKaku};
            letter-spacing: 0.15em;
            line-height: 1.6;
            text-align: center;

            @media ${breakpoints.lg} {
              font-size: ${rem(26)};
            }

            &::before,
            &::after {
              content: '';
              width: 1.5px;
              height: ${rem(40 / 2)};
              flex-shrink: 0;

              @media ${breakpoints.lg} {
                height: ${rem(32)};
                width: 3px;
              }
            }

            &::before {
              transform: rotate(-24deg);
              margin-right: ${rem(10)};

              @media ${breakpoints.lg} {
                margin-right: ${rem(20)};
              }
            }

            &::after {
              transform: rotate(24deg);
              margin-left: ${rem(10)};

              @media ${breakpoints.lg} {
                margin-left: ${rem(20)};
              }
            }
          }
        }

        > .e-slide-body {
          --body-padding-pc: ${rem(70)};

          padding-top: ${rem(14 / 2)};
          padding-left: ${rem(24 / 2)};
          padding-right: ${rem(24 / 2)};
          overflow: hidden;
          position: relative;

          @media ${breakpoints.lg} {
            /* width: ${rem(1000)}; */
            padding-top: ${rem(10)};
            margin-left: auto;
            margin-right: auto;
            padding-left: var(--body-padding-pc);
            padding-right: var(--body-padding-pc);
          }

          .swiper {
            overflow: visible;
            padding-bottom: ${rem(86 / 2)};

            @media ${breakpoints.lg} {
              padding-bottom: ${rem(56)};
            }

            &::before,
            &::after {
              content: '';
              position: absolute;
              width: var(--body-padding-pc);
              height: calc(100% + ${rem(10)});
              background: #fff;
              bottom: 0;
              z-index: 2;

              @media ${breakpoints.lg} {
              }
            }

            &::before {
              left: 100%;
            }

            &::after {
              right: 100%;
            }
          }

          .swiper-slide {
            height: auto;

            > [class^='teacherCard'] {
              height: 100%;
            }
          }

          .swiper-button-prev,
          .swiper-button-next {
            width: ${rem(55 / 2)};
            height: ${rem(55 / 2)};
            z-index: 3;

            @media ${breakpoints.lg} {
              width: ${rem(44)};
              height: ${rem(44)};
            }
          }

          .swiper-button-prev::after,
          .swiper-button-next::after {
            display: none;
          }

          .swiper-button-prev {
            background-image: url(${ArrowPrevImageSP});
            background-repeat: no-repeat;
            background-size: contain;
            left: auto;
            right: calc(50% + ${rem(140)}); // calc(140 / 375 * 100vw)

            @media ${breakpoints.lg} {
              /* background-image: url(${ArrowPrevImagePC}); */
              right: calc(50% + ${rem(390)});
            }
          }

          .swiper-button-next {
            background-image: url(${ArrowNextImageSP});
            background-repeat: no-repeat;
            background-size: contain;
            right: auto;
            left: calc(50% + ${rem(140)}); // calc(140 / 375 * 100vw)

            @media ${breakpoints.lg} {
              /* background-image: url(${ArrowNextImagePC}); */
              left: calc(50% + ${rem(390)});
            }
          }

          .swiper-pagination {
            bottom: 0;
            justify-content: center;
            font-size: ${rem(28 / 2)};
            letter-spacing: 0.06em;
            font-weight: 700;
            color: #7e7e7e;

            @media ${breakpoints.lg} {
            }
          }

          .swiper-pagination-bullet {
            width: ${rem(12)};
            height: ${rem(12)};
            background-color: #e2e2e2;
            opacity: 1;
            margin-right: 0 !important;
            margin-left: 0 !important;

            @media ${breakpoints.lg} {
            }

            &.swiper-pagination-bullet-active {
              background-color: blue;
            }
          }
        }
      }

      > .e-online {
        margin-top: ${rem(61 / 2)};

        @media ${breakpoints.lg} {
          margin-top: ${rem(51)};
        }

        > .e-online-top {
          display: flex;
          justify-content: center;
          align-items: center;

          @media ${breakpoints.lg} {
            width: ${rem(900)};
            margin-left: auto;
            margin-right: auto;
          }

          &:before,
          &:after {
            content: '';
            display: block;
            width: 100%;
            height: ${rem(3 / 2)};
            background-color: #d6d6d6;

            @media ${breakpoints.lg} {
              height: ${rem(3)};
            }
          }

          > .e-online-top-image {
            flex-shrink: 0;
            width: ${rem(82 / 2)};
            margin: 0 ${rem(17 / 2)};

            @media ${breakpoints.lg} {
              width: ${rem(57.4)};
              margin: 0 ${rem(11.9)};
            }
          }
        }

        > .e-online-image {
          width: ${rem(560 / 2)};
          margin: ${rem(44 / 2)} auto 0 auto;

          @media ${breakpoints.lg} {
            width: ${rem(448)};
            margin: ${rem(37.9)} auto 0 auto;
          }
        }
      }
    }

    > .e-cta1 {
      width: 100vw;
      margin: ${rem(100 / 2)} calc(50% - 50vw) 0;

      @media ${breakpoints.lg} {
        margin: ${rem(100)} calc(50% - 50vw) 0;
      }
    }

    > .e-cta2 {
      width: 100vw;
      margin: 0 calc(50% - 50vw) 0;

      @media ${breakpoints.lg} {
        margin: 0 calc(50% - 50vw) 0;
      }
    }

    > .e-course-overview {
      padding: ${rem(100 / 2)} 0 ${rem(101 / 2)} 0;
      color: #000;
      background-color: #ddf3ff;

      @media ${breakpoints.lg} {
        padding: ${rem(133.3)} 0 ${rem(100)} 0;
      }

      > .e-course-overview-head {
        position: relative;
        width: fit-content;
        margin: 0 auto;
        font-size: ${rem(42 / 2)};
        font-family: ${fontFamily.zenKaku};
        font-weight: bold;
        text-align: center;
        color: #0028be;
        letter-spacing: 0.06em;
        text-decoration: underline;
        text-decoration-color: #000;
        text-underline-offset: ${rem(6)};
        text-decoration-thickness: ${rem(2)};

        @media ${breakpoints.lg} {
          font-size: ${rem(32)};
          text-decoration-thickness: ${rem(3)};
        }

        &[data-anim='true'] {
          > .e-course-overview-head-ill1 {
            animation: slideFadeIn 1s 0s forwards;
          }

          > .e-course-overview-head-ill2 {
            animation: slideFadeIn 1s 0s forwards;
          }
        }

        > .e-course-overview-head-big {
          font-size: ${rem(54 / 2)};

          @media ${breakpoints.lg} {
            font-size: ${rem(42)};
          }
        }

        > .e-course-overview-head-ill1 {
          position: absolute;
          left: ${rem(-128 / 2)};
          bottom: 0;
          width: ${rem(88 / 2)};
          height: ${rem(84 / 2)};
          opacity: 0;
          transform: translateY(${rem(20)});

          @media ${breakpoints.lg} {
            left: ${rem(-118)};
            width: ${rem(65.2)};
            height: ${rem(62.69)};
          }
        }

        > .e-course-overview-head-ill2 {
          position: absolute;
          bottom: 0;
          width: ${rem(185.4 / 2)};
          height: ${rem(128.69 / 2)};
          opacity: 0;
          transform: translateY(${rem(20)});

          @media ${breakpoints.lg} {
            right: ${rem(-190)};
            width: ${rem(148.75)};
            height: ${rem(102.95)};
          }
        }
      }

      > .e-course-overview-item {
        position: relative;
        width: ${rem(670 / 2)};
        margin: ${rem(94 / 2)} auto 0 auto;

        @media ${breakpoints.lg} {
          width: ${rem(1000)};
          margin: ${rem(93)} auto 0 auto;
        }

        > .e-course-overview-item-head {
          width: ${rem(600 / 2)};
          position: absolute;
          top: ${rem(-46 / 2)};
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          justify-content: center;
          align-items: center;
          height: ${rem(92 / 2)};
          font-size: ${rem(42 / 2)};
          font-family: ${fontFamily.zenKaku};
          font-weight: bold;
          color: #0028be;
          text-align: center;
          background-color: #fff0f0;
          border: solid #000 ${rem(2)};
          border-radius: ${rem(20 / 2)};

          @media ${breakpoints.lg} {
            width: ${rem(800)};
            height: ${rem(66)};
            top: ${rem(-33)};
            border-radius: ${rem(20)};
            font-size: ${rem(32)};
            border: solid #000 ${rem(3)};
          }
        }

        > .e-course-overview-contents {
          border: solid #000 ${rem(2)};
          background-color: #fff;
          border-radius: ${rem(20 / 2)};

          @media ${breakpoints.lg} {
            border: solid #000 ${rem(3)};
            border-radius: ${rem(20)};
          }

          .e-course-overview-top-text {
            font-size: ${rem(28 / 2)};
            text-align: center;
            margin: 0;
            padding: ${rem(85.5 / 2)} 0 ${rem(46.5 / 2)} 0;
            letter-spacing: 0.06em;

            @media ${breakpoints.lg} {
              font-size: ${rem(24)};
              padding: ${rem(67.5)} 0 ${rem(40.5)} 0;
            }
          }

          > .e-course-overview-inner {
            width: ${rem(620 / 2)};
            margin: 0 auto;

            @media ${breakpoints.lg} {
              width: ${rem(800)};
            }

            > .e-course-overview-time-text {
              margin: ${rem(108 / 2)} auto 0 auto;
              max-width: ${rem(585 / 2)};
              font-size: ${rem(32 / 2)};
              line-height: calc(58 / 32);
              letter-spacing: 0.06em;

              @media ${breakpoints.lg} {
                max-width: ${rem(680)};
                margin: ${rem(66.8)} auto 0 auto;
                font-size: ${rem(24)};
                line-height: calc(58 / 24);
              }
            }

            > .e-course-overview-schedule {
              margin: ${rem(111 / 2)} auto 0 auto;

              @media ${breakpoints.lg} {
                margin: ${rem(52.5)} auto 0 auto;
              }

              > .e-course-overview-schedule-list {
                margin: 0;

                > .e-course-overview-schedule-list-item {
                  display: flex;
                  align-items: center;
                  margin: 0;
                  padding: ${rem(18 / 2)} 0;
                  font-size: ${rem(28 / 2)};
                  border-bottom: solid #adadad ${rem(1)};

                  @media ${breakpoints.lg} {
                    padding: ${rem(14)} ${rem(220)} ${rem(14)} ${rem(200)};
                    font-size: ${rem(18)};
                  }

                  > .e-course-overview-schedule-list-item-icon {
                    width: ${rem(19.93 / 2)};
                    height: ${rem(18.65 / 2)};
                    margin: 0 ${rem(10 / 2)};

                    @media ${breakpoints.lg} {
                      width: ${rem(19)};
                      height: ${rem(17.78)};
                      margin: 0 ${rem(10)};
                    }
                  }
                }
              }
            }

            > .e-course-overview-timetable {
              > .e-course-overview-timetable-list {
                margin: 0;

                > .e-course-overview-timetable-list-item {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin: 0;
                  padding: ${rem(18 / 2)} 0;
                  font-size: ${rem(28 / 2)};
                  border-bottom: solid #adadad ${rem(1)};

                  @media ${breakpoints.lg} {
                    padding: ${rem(14)} 0;
                    font-size: ${rem(18)};
                  }

                  > .e-course-overview-timetable-list-item-icon {
                    width: ${rem(19.93 / 2)};
                    height: ${rem(18.65 / 2)};
                    margin: 0 ${rem(10 / 2)};

                    @media ${breakpoints.lg} {
                      width: ${rem(19)};
                      height: ${rem(17.78)};
                      margin: 0 ${rem(10)} 0 0;
                    }
                  }
                }
              }
            }

            > .e-course-overview-subject {
              > .e-course-overview-subject-list {
                display: flex;
                justify-content: center;
                gap: ${rem(40 / 2)};
                border-bottom: solid #adadad ${rem(1)};
                margin: 0;

                @media ${breakpoints.lg} {
                  gap: ${rem(50)};
                }

                > .e-course-overview-subject-list-item {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin: 0;
                  padding: ${rem(18 / 2)} 0;
                  font-size: ${rem(28 / 2)};
                  word-break: keep-all;

                  @media ${breakpoints.lg} {
                    padding: ${rem(14)} 0;
                    font-size: ${rem(18)};
                  }

                  > .e-course-overview-subject-list-item-icon {
                    width: ${rem(19.93 / 2)};
                    height: ${rem(18.65 / 2)};
                    margin: 0 ${rem(10 / 2)} 0 0;

                    @media ${breakpoints.lg} {
                      width: ${rem(19)};
                      height: ${rem(17.78)};
                      margin: 0 ${rem(10)} 0 0;
                    }
                  }
                }
              }
            }

            > .e-course-overview-example {
              margin: ${rem(70 / 2)} auto ${rem(80 / 2)} auto;
              padding: 0 0 ${rem(24 / 2)} 0;
              border-bottom: solid #ff1a56 ${rem(2)};

              @media ${breakpoints.lg} {
                margin: ${rem(50)} auto ${rem(60)} auto;
                padding: 0 0 ${rem(18)} 0;
                border-bottom: solid #ff1a56 ${rem(3)};
              }

              > .e-course-overview-example-head {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: ${rem(36 / 2)};
                font-family: bold;
                word-break: keep-all;
                color: #ff1a56;
                font-family: ${fontFamily.zenKaku};

                @media ${breakpoints.lg} {
                  font-size: ${rem(28)};
                }

                &::before,
                &::after {
                  display: block;
                  content: '';
                  width: 100%;
                  height: ${rem(2)};
                  background-color: #ff1a56;

                  @media ${breakpoints.lg} {
                    height: ${rem(3)};
                  }
                }

                &::before {
                  margin: 0 ${rem(18 / 2)} 0 0;
                }

                &::after {
                  margin: 0 0 0 ${rem(18 / 2)};
                }
              }

              .e-course-overview-example-text {
                text-align: center;
                margin: 0;
                line-height: calc(50 / 28);
                font-size: ${rem(28 / 2)};

                @media ${breakpoints.lg} {
                  font-size: ${rem(18)};
                  line-height: calc(50 / 18);
                }
              }

              .e-course-overview-example-icon-text {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: ${rem(12 / 2)} 0 0 0;

                @media ${breakpoints.lg} {
                  margin: 0;
                }

                > .e-course-overview-example-icon {
                  width: ${rem(70 / 2)};
                  height: ${rem(40.01 / 2)};
                  margin: 0 ${rem(10 / 2)} 0 0;

                  @media ${breakpoints.lg} {
                    width: ${rem(56.85)};
                    height: ${rem(32.49)};
                    margin: 0 ${rem(10)} 0 0;
                  }
                }
              }

              .e-course-overview-example-contents {
                @media ${breakpoints.lg} {
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  margin: ${rem(10)} 0 0 0;
                }
              }
            }

            > .e-course-overview-format {
              margin: ${rem(72 / 2)} 0 ${rem(60 / 2)} 0;

              @media ${breakpoints.lg} {
                margin: ${rem(50)} 0 ${rem(50)} 0;
              }

              > .e-course-overview-format-text {
                text-align: center;
                margin: 0;
                padding: ${rem(16 / 2)} 0;
                font-size: ${rem(28 / 2)};

                @media ${breakpoints.lg} {
                  margin: ${rem(0)} 0 0 0;
                  padding: ${rem(16)} 0;
                  font-size: ${rem(18)};
                }
              }

              > .e-course-overview-format-list {
                list-style: none;
                margin: 0;

                @media ${breakpoints.lg} {
                  display: flex;
                  justify-content: space-between;
                  gap: ${rem(60)};
                }

                > .e-course-overview-format-list-item {
                  text-align: center;
                  margin: 0;
                  padding: ${rem(16 / 2)} 0;
                  border-bottom: solid #adadad ${rem(1)};
                  font-size: ${rem(28 / 2)};

                  @media ${breakpoints.lg} {
                    width: 100%;
                    padding: ${rem(12)} 0;
                    border-top: solid #adadad ${rem(1)};
                    border-bottom: solid #adadad ${rem(1)};
                    font-size: ${rem(18)};
                  }

                  &:first-child {
                    border-top: solid #adadad ${rem(1)};
                  }
                }
              }
            }

            > .e-course-overview-price-list {
              margin: ${rem(92 / 2)} 0 0 0;
              list-style: none;

              @media ${breakpoints.lg} {
                display: flex;
                justify-content: space-between;
                gap: ${rem(55)};
                margin: ${rem(60)} 0 0 0;
              }

              > .e-course-overview-price-list-item {
                margin: 0;
                padding: ${rem(16 / 2)} 0;
                border-bottom: solid #adadad ${rem(1)};
                font-size: ${rem(28 / 2)};

                @media ${breakpoints.lg} {
                  text-align: center;
                  width: 100%;
                  padding: ${rem(12)} 0;
                  border-top: solid #adadad ${rem(1)};
                  border-bottom: solid #adadad ${rem(1)};
                  font-size: ${rem(18)};
                }

                &:first-child {
                  border-top: solid #adadad ${rem(1)};
                }

                > .e-course-overview-price-item-text {
                  display: block;
                  width: ${rem(250 / 2)};
                  font-size: ${rem(28 / 2)};
                  margin: 0 auto;

                  @media ${breakpoints.lg} {
                    width: ${rem(200)};
                    font-size: ${rem(18)};
                  }
                }
              }
            }

            > .e-course-overview-price-list-notes {
              margin: ${rem(12.5 / 2)} 0 ${rem(40 / 2)} 0;
              list-style: none;
              font-size: ${rem(22 / 2)};
              line-height: calc(34 / 22);

              @media ${breakpoints.lg} {
                margin: ${rem(17.5)} 0 ${rem(40)} 0;
                font-size: ${rem(14)};
                line-height: calc(24 / 14);
              }

              > .e-course-overview-price-list-notes-item {
                margin: 0;
              }
            }

            .e-course-overview-arrow {
              margin: ${rem(30 / 2)} auto;
              width: ${rem(30 / 2)};
              height: ${rem(30 / 2)};

              @media ${breakpoints.lg} {
                margin: ${rem(23)} auto;
                width: ${rem(30)};
                height: ${rem(30)};
              }
            }

            .e-course-overview-time-head {
              display: flex;
              justify-content: center;
              align-items: center;
              width: ${rem(620 / 2)};
              height: ${rem(70 / 2)};
              font-size: ${rem(32 / 2)};
              background-color: #fff0f0;
              letter-spacing: 0.06em;
              font-family: ${fontFamily.zenKaku};

              @media ${breakpoints.lg} {
                width: ${rem(800)};
                height: ${rem(56)};
                font-size: ${rem(24)};
              }

              .e-course-overview-time-head-highlight {
                font-size: ${rem(42 / 2)};
                font-weight: bold;
                color: #ff1a56;

                @media ${breakpoints.lg} {
                  font-size: ${rem(32)};
                }
              }
            }
          }
        }
      }

      > .e-course-overview-close {
        margin: ${rem(60 / 2)} auto 0 auto;

        @media ${breakpoints.lg} {
          width: ${rem(400)};
          margin: ${rem(60)} auto 0 auto;
        }

        > .e-course-overview-close-text {
          font-size: ${rem(36 / 2)};
          text-align: center;
          margin: 0;
          font-family: ${fontFamily.zenKaku};
          font-weight: bold;

          @media ${breakpoints.lg} {
            font-size: ${rem(32)};
          }
        }

        > .e-course-overview-close-arrow {
          width: ${rem(410 / 2)};
          height: ${rem(12.79 / 2)};
          margin: ${rem(13.5 / 2)} auto 0 auto;

          @media ${breakpoints.lg} {
            width: ${rem(339.54)};
            height: ${rem(10.6)};
            margin: ${rem(13.5)} auto 0 auto;
          }
        }

        > .e-course-overview-close-date {
          font-size: ${rem(42 / 2)};
          text-align: center;
          margin: ${rem(12.7 / 2)} 0 0 0;
          color: #0028be;
          font-family: ${fontFamily.zenKaku};
          font-weight: bold;

          @media ${breakpoints.lg} {
            font-size: ${rem(38)};
            margin: 0;
          }
        }

        > .e-course-overview-close-notes {
          list-style: none;
          margin: ${rem(22 / 2)} auto 0 auto;
          width: ${rem(590 / 2)};

          @media ${breakpoints.lg} {
            width: 100%;
            margin: ${rem(24)} auto 0 auto;
          }

          > .e-course-overview-close-notes-item {
            font-size: ${rem(22 / 2)};
            margin: 0;

            @media ${breakpoints.lg} {
              font-size: ${rem(14)};
            }
          }
        }
      }

      .overview-top {
        margin-top: ${rem(106 / 2)};

        @media ${breakpoints.lg} {
          margin-top: ${rem(106)};
        }
      }
    }
  }

  .point-item-text1 {
    margin: ${rem(40 / 2)} auto 0 auto !important;

    @media ${breakpoints.lg} {
      margin: ${rem(45)} auto 0 auto !important;
    }
  }

  .point-item-text2 {
    margin: ${rem(45 / 2)} auto 0 auto !important;

    @media ${breakpoints.lg} {
      margin: ${rem(27.1)} auto 0 auto !important;
    }
  }

  .point-item-text3 {
    margin: ${rem(45 / 2)} auto 0 auto !important;

    @media ${breakpoints.lg} {
      margin: ${rem(21.2)} auto 0 auto !important;
    }
  }

  .point-item-text4 {
    margin: ${rem(40 / 2)} auto 0 auto !important;

    @media ${breakpoints.lg} {
      margin: ${rem(30)} auto 0 auto !important;
    }
  }

  .point-item-text5 {
    margin: ${rem(30 / 2)} auto 0 auto !important;

    @media ${breakpoints.lg} {
      margin: ${rem(30)} auto 0 auto !important;
    }
  }

  .arrow-1 {
    margin: ${rem(43 / 2)} auto 0 auto !important;

    @media ${breakpoints.lg} {
      margin: ${rem(37.1)} auto 0 auto !important;
    }
  }

  .arrow-2 {
    margin: ${rem(48 / 2)} auto 0 auto !important;

    @media ${breakpoints.lg} {
      margin: ${rem(29)} auto 0 auto !important;
    }
  }

  .sub-head1 {
    margin: ${rem(80 / 2)} auto 0 auto !important;

    @media ${breakpoints.lg} {
      margin: ${rem(53.4)} auto 0 auto !important;
    }
  }

  .sub-head2 {
    margin: ${rem(80 / 2)} auto 0 auto !important;

    @media ${breakpoints.lg} {
      margin: ${rem(50.4)} auto 0 auto !important;
    }
  }

  .more-text {
    width: ${rem(520 / 2)} !important;
    margin: ${rem(26 / 2)} auto 0 auto !important;

    @media ${breakpoints.lg} {
      width: ${rem(460)} !important;
      margin: ${rem(20.8)} auto 0 auto !important;
    }
  }

  .text-bold {
    font-weight: bold;
  }

  .text-highlight-bold {
    color: #ff1a56;
    font-weight: bold;
  }

  .text-underline {
    text-decoration: underline;
    text-decoration-color: #000;
    text-underline-offset: ${rem(3)};
    text-decoration-thickness: ${rem(2)};

    @media ${breakpoints.lg} {
      text-decoration-thickness: ${rem(3)};
    }
  }

  .is-sp {
    display: block;

    @media ${breakpoints.lg} {
      display: none;
    }
  }

  .is-pc {
    display: none;

    @media ${breakpoints.lg} {
      display: block;
    }
  }

  @keyframes slideFadeIn {
    0% {
      opacity: 0;
      transform: translateY(${rem(20)});
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

export const CourseJuniorHighSchool: (props: CourseJuniorHighSchoolProps) => JSX.Element = StyledComponent

export default CourseJuniorHighSchool
