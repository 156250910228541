import React from 'react'
import styled from 'styled-components'
import { rem, breakpoints, innerWidth, fontFamily, summerColor } from 'variables/_index'
import ContactButton from './atom/contactButton'
import { siteStructure } from 'utils/site'
import classNames from 'classnames'
import { StaticImage } from 'gatsby-plugin-image'

export interface ContactSectionProps {
  readonly type: 'elementary' | 'junior' | 'high'
  readonly balloonImageElement: JSX.Element
  readonly text: boolean
}

type ContactSectionPropsClassName = ContactSectionProps & {
  readonly className: string
}

function Component({ className, type, balloonImageElement, text }: ContactSectionPropsClassName) {
  const ctaImage =
    type === 'elementary' ? (
      <StaticImage src={`../../../assets/images/spring/contact/cta_sho.png`} alt="春期講習4日間を無料で体験" placeholder="none" loading="eager" />
    ) : type === 'junior' ? (
      <StaticImage src={`../../../assets/images/spring/contact/cta_chu.png`} alt="春期講習4日間を無料で体験" placeholder="none" loading="eager" />
    ) : (
      <StaticImage src={`../../../assets/images/spring/contact/cta_kou.png`} alt="春期講習4日間を無料で体験" placeholder="none" loading="eager" />
    )

  return (
    <div className={className}>
      <div className={classNames('e-contact-area')}>
        <div className="e-inner">
          <div className="e-image">{ctaImage}</div>

          <div className="e-buttons">
            <div className="e-button">
              <ContactButton href={siteStructure.summerContact.path} imageElement={balloonImageElement} />
            </div>
          </div>

          {text && (
            <div className="e-text">
              <StaticImage
                src={`../../../assets/images/spring/contact/cta_text.png`}
                alt="講習受講後に入会する場合入会金22,000円が無料！"
                placeholder="none"
                loading="eager"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<ContactSectionPropsClassName>`
  font-weight: 700;
  text-align: center;
  position: relative;

  > .e-contact-area {
    background-color: #ffe10a;
    padding-top: ${rem(40 / 2)};
    padding-bottom: ${rem(60 / 2)};

    @media ${breakpoints.lg} {
      padding-top: ${rem(30)};
      padding-bottom: ${rem(50)};
    }

    > .e-inner {
      width: ${rem(600 / 2)};
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;

      @media ${breakpoints.lg} {
        width: ${rem(500)};
      }

      > .e-buttons {
        margin-top: ${rem(10)};

        @media ${breakpoints.lg} {
          margin-top: ${rem(10)};
        }

        > .e-button {
          text-align: center;

          &:nth-child(n + 2) {
            margin-top: ${rem(20 / 2)};

            @media ${breakpoints.lg} {
              margin-top: ${rem(16)};
            }
          }
        }
      }

      > .e-text {
        padding: ${rem(48 / 2)} 0 0 0;

        @media ${breakpoints.lg} {
          width: ${rem(499.38)};
          padding: ${rem(31.3)} 0 0 0;
        }
      }
    }
  }
`

export const ContactSection: (props: ContactSectionProps) => JSX.Element = StyledComponent

export default ContactSection
