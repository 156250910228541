import React, { useState } from 'react'
import styled from 'styled-components'
import { rem, breakpoints, innerWidth, fontFamily, springColor } from 'variables/_index'
import { StaticImage } from 'gatsby-plugin-image'
import CourseTab from './atom/courseTab'
import classNames from 'classnames'
import CourseJuniorHighSchool from './group/courseJuniorHighSchool'
import CoursePrimary from './group/coursePrimary'
import CourseHighSchool from './group/courseHighSchool'
import SectionHeading from './atom/sectionHeading'

export interface CourseSectionProps {
  readonly noProps?: string
}

type CourseSectionPropsClassName = CourseSectionProps & {
  readonly className: string
}

function Component({ className }: CourseSectionPropsClassName) {
  const [currentTab, setCurrentTab] = useState<'primary' | 'junior-high-school' | 'high-school'>('junior-high-school')

  const handleTabClick = (selectTab: 'primary' | 'junior-high-school' | 'high-school') => {
    setCurrentTab(selectTab)
  }

  return (
    <div className={className}>
      <div className="e-inner">
        <h2 className="e-course-head">
          <span className="e-course-head-jp">コース紹介</span>
          <span className="e-course-head-en">course</span>
        </h2>

        <div className="e-course-items">
          <div className="e-course-item">
            <CourseTab
              gradeImage={<StaticImage src="../../../assets/images/spring/course/course-primary.png" alt="" placeholder="none" />}
              gradeText="小学生"
              onClick={() => handleTabClick('primary')}
              isCurrent={currentTab === 'primary'}
            />
          </div>
          <div className="e-course-item">
            <CourseTab
              gradeImage={<StaticImage src="../../../assets/images/spring/course/course-junior-high-school.png" alt="" placeholder="none" />}
              gradeText="中学生"
              onClick={() => handleTabClick('junior-high-school')}
              isCurrent={currentTab === 'junior-high-school'}
            />
          </div>
          <div className="e-course-item">
            <CourseTab
              gradeImage={<StaticImage src="../../../assets/images/spring/course/course-high-school.png" alt="" placeholder="none" />}
              gradeText="高校生"
              onClick={() => handleTabClick('high-school')}
              isCurrent={currentTab === 'high-school'}
            />
          </div>
        </div>
        <div className={classNames('e-tab-content', 'is-primary', currentTab === 'primary' && 'is-current')}>
          <CoursePrimary />
        </div>
        <div className={classNames('e-tab-content', 'is-primary', currentTab === 'junior-high-school' && 'is-current')}>
          <CourseJuniorHighSchool />
        </div>
        <div className={classNames('e-tab-content', 'is-primary', currentTab === 'high-school' && 'is-current')}>
          <CourseHighSchool />
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<CourseSectionPropsClassName>`
  padding-top: ${rem(120 / 2)};

  @media ${breakpoints.lg} {
    padding-top: ${rem(120)};
  }

  > .e-inner {
    > .e-course-head {
      text-align: center;
      display: flex;
      flex-direction: column;

      > .e-course-head-jp {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: ${rem(68 / 2)};
        font-family: ${fontFamily.zenKaku};
        color: #0028be;

        @media ${breakpoints.lg} {
          font-size: ${rem(54)};
          font-weight: bold;
        }

        &::after {
          display: block;
          content: '';
          width: ${rem(12 / 2)};
          height: ${rem(12 / 2)};
          margin-top: ${rem(12 / 2)};
          border-radius: ${rem(3)};
          background-color: #ff1a56;

          @media ${breakpoints.lg} {
            width: ${rem(10)};
            height: ${rem(10)};
            margin-top: ${rem(10)};
            border-radius: ${rem(5)};
          }
        }
      }

      > .e-course-head-en {
        font-family: ${fontFamily.montserrat};
        color: #acacac;
        font-size: ${rem(38 / 2)};
        margin-top: ${rem(15 / 2)};

        @media ${breakpoints.lg} {
          margin-top: ${rem(11.8)};
          font-size: ${rem(30)};
          font-weight: 600;
          letter-spacing: 0.2em;
        }
      }
    }

    > .e-course-items {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      margin-top: ${rem(60 / 2)};
      gap: ${rem(15 / 2)};

      @media ${breakpoints.lg} {
        gap: ${rem(25)};
        margin-top: ${rem(40)};
      }

      > .e-course-item {
        width: 100%;
        max-width: ${rem(210 / 2)};

        @media ${breakpoints.lg} {
          max-width: ${rem(310)};
        }
      }
    }

    > .e-tab-content {
      visibility: hidden;
      height: 0;
      overflow: hidden;

      &.is-current {
        visibility: visible;
        height: auto;
      }
    }
  }
`

export const CourseSection: (props: CourseSectionProps) => JSX.Element = StyledComponent

export default CourseSection
