import React from 'react'
import styled from 'styled-components'
import { breakpoints, rem, springColor } from 'variables/_index'

export interface TeacherCardProps {
  readonly title: string
  readonly imageElement: JSX.Element
  readonly position: string
  readonly text: JSX.Element
}

type TeacherCardPropsClassName = TeacherCardProps & {
  readonly className: string
}

function Component({ className, title, imageElement, position, text }: TeacherCardPropsClassName) {
  return (
    <div className={className}>
      <div className="e-head">
        <div className="e-title">{title}</div>
        <div className="e-pos">{position}</div>
      </div>
      <div className="e-image">{imageElement}</div>
      <div className="e-text-area">
        <div className="e-text">{text}</div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<TeacherCardPropsClassName>`
  background-color: ${springColor.fitMain};
  border-radius: ${rem(10)};
  padding: ${rem(136 / 2)} ${rem(30 / 2)} ${rem(30 / 2)};
  text-align: center;
  color: #fff;
  position: relative;

  @media ${breakpoints.lg} {
    border-radius: ${rem(15)};
    padding-top: ${rem(110)};
    padding-inline: ${rem(24)};
    padding-bottom: ${rem(40)};
  }

  > .e-head {
    background: #00a4d1;
    border-radius: ${rem(10 / 2)} ${rem(10 / 2)} ${rem(20 / 2)} ${rem(20 / 2)};
    padding: ${rem(20 / 2)} 0 ${rem(14 / 2)};
    width: calc(100% - ${rem(20 / 2)} * 2);
    height: ${rem(130 / 2)};
    overflow: hidden;
    position: absolute;
    top: ${rem(-14 / 2)};
    left: 50%;
    transform: translateX(-50%);

    @media ${breakpoints.lg} {
      border-radius: ${rem(10)} ${rem(10)} ${rem(20)} ${rem(20)};
      padding-top: ${rem(16)};
      padding-bottom: ${rem(12)};
      width: calc(100% - ${rem(24)} * 2);
      height: ${rem(104)};
      top: ${rem(-10)};
    }

    > .e-title {
      font-size: ${rem(36 / 2)};
      font-weight: 700;
      text-align: center;
      padding-left: ${rem(20 / 2)};
      padding-right: ${rem(20 / 2)};

      @media ${breakpoints.lg} {
        font-size: ${rem(28)};
      }
    }

    > .e-pos {
      margin-top: ${rem(4 / 2)};
      font-size: ${rem(26 / 2)};
      font-weight: 700;

      @media ${breakpoints.lg} {
        margin-top: ${rem(5)};
        font-size: ${rem(20)};
      }
    }
  }

  > .e-image {
    text-align: center;

    @media ${breakpoints.lg} {
    }

    img {
      width: ${rem(240 / 2)};
      max-width: 100%;

      @media ${breakpoints.lg} {
        width: ${rem(270)};
      }
    }
  }

  > .e-text-area {
    margin-top: ${rem(20 / 2)};
    text-align: left;

    @media ${breakpoints.lg} {
      margin-top: ${rem(15)};
    }

    > .e-text {
      display: inline;
      line-height: calc(64 / 32);
      font-size: ${rem(32 / 2)};
      letter-spacing: 0.06em;
      font-weight: 700;
      color: #fff;
      border-bottom: 1px solid currentColor;

      @media ${breakpoints.lg} {
        font-size: ${rem(22)};
        line-height: calc(44 / 22);
      }
    }
  }
`

export const TeacherCard: (props: TeacherCardProps) => JSX.Element = StyledComponent

export default TeacherCard
