import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'
import { rem, fontFamily, breakpoints } from 'variables/_index'
import ContactSection from '../contactSection'

export interface CoursePrimaryProps {
  readonly noProps?: string
}

type CoursePrimaryPropsClassName = CoursePrimaryProps & {
  readonly className: string
}

function Component({ className }: CoursePrimaryPropsClassName) {
  const { ref, inView } = useInView({
    rootMargin: '-100px', // ref要素が現れてから100px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  })

  return (
    <div className={className}>
      <div className="e-course">
        <h3 className="e-course-head">小学生コース</h3>

        <div className="e-course-img">
          <StaticImage src="../../../../assets/images/spring/course/primary/course-primary-img.png" alt="" placeholder="none" />
        </div>

        <div className="e-course-curriculum">
          <h4 ref={ref} className="e-course-curriculum-head" data-anim={inView}>
            <span className="e-course-curriculum-illust1">
              <StaticImage src="../../../../assets/images/spring/course/ill_book.png" alt="" placeholder="none" />
            </span>
            春期講習の
            <br />
            <span className="e-course-curriculum-head-highlight">
              <span className="e-course-curriculum-head-big">2</span>つ
            </span>
            <span className="e-course-curriculum-head-middle">のカリキュラム</span>
            <span className="e-course-curriculum-illust2">
              <StaticImage src="../../../../assets/images/spring/course/ill_pen.png" alt="" placeholder="none" />
            </span>
          </h4>

          <ul className="e-course-curriculum-list">
            <li className="e-course-curriculum-list-item">
              <span className="e-course-curriculum-list-item-number">1.</span>集団WEB授業
            </li>
            <li className="e-course-curriculum-list-item">
              <span className="e-course-curriculum-list-item-number">2.</span>わくわく理科実験講座
            </li>
          </ul>
          <h5 className="e-course-curriculum-item-head">
            <span className="e-course-curriculum-item-head-badge" data-type="primary1">
              <span className="e-course-curriculum-item-head-number">1</span>
            </span>
            <span className="is-small">中学で必要な学力が身につく</span>
            <br />
            集団WEB授業
          </h5>

          <div className="e-course-curriculum1-contents">
            <div className="e-course-curriculum1-contents-top">
              <div className="e-course-curriculum1-contents-image">
                <StaticImage src="../../../../assets/images/spring/course/primary/course_sho_photo.png" alt="" placeholder="none" />
              </div>
              <p className="e-course-curriculum1-contents-text">
                <span className="is-highlight">中学で学ぶ単元の土台となる基礎を復習し、定着させます。</span>
                発言や質問をしながら進める対面式授業だから、自宅でも集中して楽しく勉強ができます。
              </p>
            </div>

            <div className="e-course-curriculum1-target">
              <h3 className="e-course-curriculums-contents-head">
                <span className="e-course-curriculums-contents-head-text">対象</span>
              </h3>
              <p className="e-course-curriculums-text">新小学4年生～新小学6年生</p>
            </div>

            <div className="e-course-curriculum1-date">
              <h3 className="e-course-curriculums-contents-head">
                <span className="e-course-curriculums-contents-head-text">日程</span>
              </h3>

              <ul className="e-course-curriculums-list">
                <li className="e-course-curriculums-list-item">
                  <span className="e-course-curriculums-list-item-head">新小学4・新小学5年生</span>
                  <span className="e-course-curriculums-list-item-contents">3/25(月)~3/29(金)の5日間</span>
                </li>
                <li className="e-course-curriculums-list-item">
                  <span className="e-course-curriculums-list-item-head">新小学6年生</span>
                  <span className="e-course-curriculums-list-item-contents">3/25(月)~3/30(土)の6日間</span>
                </li>
              </ul>
            </div>

            <div className="e-course-curriculum1-time">
              <h3 className="e-course-curriculums-contents-head">
                <span className="e-course-curriculums-contents-head-text">時間</span>
              </h3>
              <p className="e-course-curriculums-text">19:00~20:30（1コマ40分/1日2コマ）</p>
            </div>

            <div className="e-course-curriculum1-subject">
              <h3 className="e-course-curriculums-contents-head">
                <span className="e-course-curriculums-contents-head-text">教科</span>
              </h3>

              <ul className="e-course-curriculums-list" data-row="3">
                <li className="e-course-curriculums-list-item">
                  <span className="e-course-curriculums-list-item-head">新小学4年生</span>
                  <span className="e-course-curriculums-list-item-contents">算数･国語</span>
                </li>
                <li className="e-course-curriculums-list-item">
                  <span className="e-course-curriculums-list-item-head">新小学5年生</span>
                  <span className="e-course-curriculums-list-item-contents">算数･国語･理科･社会</span>
                </li>
                <li className="e-course-curriculums-list-item">
                  <span className="e-course-curriculums-list-item-head">新小学6年生</span>
                  <span className="e-course-curriculums-list-item-contents">算数･国語･英語･理科･社会</span>
                </li>
              </ul>
            </div>

            <div className="e-course-curriculum1-price">
              <h3 className="e-course-curriculums-contents-head">
                <span className="e-course-curriculums-contents-head-text">料金</span>
              </h3>

              <ul className="e-course-curriculums-list" data-row="3">
                <li className="e-course-curriculums-list-item" data-type="price">
                  <span className="e-course-curriculums-list-item-head">新小学4年生</span>
                  <span className="e-course-curriculums-list-item-contents" data-type="price">
                    受講料　｜<span className="is-bold">11,000円</span>
                  </span>
                  <span className="e-course-curriculums-list-item-contents" data-type="price">
                    テキスト｜<span className="is-bold">1,370円</span>
                  </span>
                </li>
                <li className="e-course-curriculums-list-item" data-type="price">
                  <span className="e-course-curriculums-list-item-head">新小学5年生</span>
                  <span className="e-course-curriculums-list-item-contents" data-type="price">
                    受講料　｜<span className="is-bold">11,000円</span>
                  </span>
                  <span className="e-course-curriculums-list-item-contents" data-type="price">
                    テキスト｜<span className="is-bold">2,520円</span>
                  </span>
                </li>
                <li className="e-course-curriculums-list-item" data-type="price">
                  <span className="e-course-curriculums-list-item-head">新小学6年生</span>
                  <span className="e-course-curriculums-list-item-contents" data-type="price">
                    受講料　｜<span className="is-bold">15,000円</span>
                  </span>
                  <span className="e-course-curriculums-list-item-contents" data-type="price">
                    テキスト｜<span className="is-bold">3,205円</span>
                  </span>
                </li>
              </ul>

              <span className="e-course-curriculum1-price-notes">&#8251;表示価格は税込みです。</span>
            </div>
          </div>

          <h5 className="e-course-curriculum-item-head" data-type="curriculum2">
            <span className="e-course-curriculum-item-head-badge" data-type="primary2">
              <span className="e-course-curriculum-item-head-number">2</span>
            </span>
            自宅で実験ができる
            <br />
            わくわく理科実験講座
          </h5>

          <div className="e-course-curriculum2-contents">
            <p className="e-course-curriculum2-contents-text">
              <span className="is-highlight">実験キットが自宅に届くから、実際に見て、触って、学べる！</span>
              先生と一緒に、実験から考察まで行います。理科や実験が好きなお子さまにおススメです。スマホでの受講が可能です。
            </p>

            <div className="e-course-curriculum2-target">
              <h3 className="e-course-curriculums-contents-head">
                <span className="e-course-curriculums-contents-head-text">対象</span>
              </h3>
              <p className="e-course-curriculums-text">新小学1年生～新小学6年生</p>
            </div>

            <div className="e-course-curriculum2-experiment">
              <h3 className="e-course-curriculums-contents-head">
                <span className="e-course-curriculums-contents-head-text">実験内容</span>
              </h3>

              <ul className="e-course-curriculums-list">
                <li className="e-course-curriculums-list-item">
                  <span className="e-course-curriculums-list-item-head">新小学1～新小学4年生</span>
                  <span className="e-course-curriculums-list-item-contents">空気マジック</span>
                </li>
                <li className="e-course-curriculums-list-item">
                  <span className="e-course-curriculums-list-item-head">新小学5年生・新小学6年生</span>
                  <span className="e-course-curriculums-list-item-contents">反射と屈折</span>
                </li>
              </ul>
            </div>

            <div className="e-course-curriculum2-date">
              <h3 className="e-course-curriculums-contents-head">
                <span className="e-course-curriculums-contents-head-text">日程・時間</span>
              </h3>
              <p className="e-course-curriculums-text">3/23(土)　11:00~12:10</p>
            </div>

            <div className="e-course-curriculum2-price">
              <h3 className="e-course-curriculums-contents-head">
                <span className="e-course-curriculums-contents-head-text">料金</span>
              </h3>
              <p className="e-course-curriculums-text">
                <span className="is-bold">4,400円</span>
              </p>

              <ul className="e-course-curriculum2-price-notes">
                <li className="e-course-curriculum2-price-notes-item">&#8251;表示価格は税込みです。</li>
                <li className="e-course-curriculum2-price-notes-item">&#8251;教材の料金を含みます。</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="e-course-overview-close">
          <p className="e-course-overview-close-text">最終申し込み締切日</p>
          <div className="e-course-overview-close-arrow">
            <StaticImage src="../../../../assets/images/spring/course/close_hukidashi.png" alt="" placeholder="none" />
          </div>
          <p className="e-course-overview-close-date">3/2(土)</p>

          <ul className="e-course-overview-close-notes">
            <li className="e-course-overview-close-notes-item">&#8251;満席になり次第、申し込みを締め切る場合があります。</li>
            <li className="e-course-overview-close-notes-item">&#8251;申し込み締切日は各日程によって異なります。</li>
          </ul>
        </div>

        <div className="e-cta">
          <ContactSection
            type="elementary"
            balloonImageElement={<StaticImage src="../assets/images/illustration/illustration16-2.png" alt="" placeholder="none" />}
            text={false}
          />
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<CoursePrimaryPropsClassName>`
  > .e-course {
    margin-top: ${rem(-2)};
    border-top: ${rem(2)} solid #000;
    background-color: #ade2ff;

    @media ${breakpoints.lg} {
      margin-top: ${rem(-3)};
      border-top: ${rem(3)} solid #000;
    }

    > .e-course-head {
      text-align: center;
      border-top: ${rem(2)} solid #000;
      border-bottom: ${rem(2)} solid #000;
      width: ${rem(370 / 2)};
      margin: ${rem(70 / 2)} auto 0 auto;
      padding: ${rem(10 / 2)} 0;
      color: #000000;
      font-size: ${rem(58 / 2)};
      font-family: ${fontFamily.zenKaku};

      @media ${breakpoints.lg} {
        width: ${rem(293)};
        margin: ${rem(80)} auto 0 auto;
        padding: ${rem(10)} 0;
        border-top: ${rem(3)} solid #000;
        border-bottom: ${rem(3)} solid #000;
        font-size: ${rem(42)};
      }
    }

    > .e-course-img {
      width: ${rem(302 / 2)};
      margin: ${rem(38.5 / 2)} auto 0 auto;

      @media ${breakpoints.lg} {
        width: ${rem(273)};
        margin: ${rem(49.2)} auto 0 auto;
      }
    }

    > .e-course-curriculum {
      background-color: #fff;
      border-radius: ${rem(20 / 2)};
      width: ${rem(670 / 2)};
      margin: ${rem(20 / 2)} auto 0 auto;
      padding: ${rem(47 / 2)} 0 ${rem(60 / 2)} 0;

      @media ${breakpoints.lg} {
        width: ${rem(1000)};
        margin: ${rem(20)} auto 0 auto;
        padding: ${rem(38.9)} 0 ${rem(57)} 0;
      }

      > .e-course-curriculum-head {
        position: relative;
        width: fit-content;
        margin: 0 auto;
        font-family: ${fontFamily.zenKaku};
        font-size: ${rem(42 / 2)};
        text-align: center;
        color: #0028be;
        text-decoration: underline;
        text-decoration-color: #000;
        text-underline-offset: ${rem(6)};
        letter-spacing: 0.06em;

        @media ${breakpoints.lg} {
          font-size: ${rem(32)};
        }

        &[data-anim='true'] {
          > .e-course-curriculum-illust1 {
            animation: slideFadeIn 1s 0s forwards;
          }

          > .e-course-curriculum-illust2 {
            animation: slideFadeIn 1s 0s forwards;
          }
        }

        > .e-course-curriculum-head-highlight {
          font-size: ${rem(54 / 2)};
          color: #ff1a56;
          letter-spacing: 0.06em;

          @media ${breakpoints.lg} {
            font-size: ${rem(42)};
          }

          > .e-course-curriculum-head-big {
            font-family: ${fontFamily.montserrat};
            font-size: ${rem(68 / 2)};
            letter-spacing: 0.06em;

            @media ${breakpoints.lg} {
              font-size: ${rem(54)};
            }
          }
        }
        > .e-course-curriculum-head-middle {
          font-size: ${rem(54 / 2)};
          letter-spacing: 0.06em;

          @media ${breakpoints.lg} {
            font-size: ${rem(42)};
          }
        }

        > .e-course-curriculum-illust1 {
          position: absolute;
          width: ${rem(130 / 2)};
          top: ${rem(-80 / 2)};
          left: ${rem(-60 / 2)};
          opacity: 0;
          transform: translateY(${rem(20)});

          @media ${breakpoints.lg} {
            width: ${rem(100)};
            top: ${rem(-25)};
            left: ${rem(-128)};
          }
        }

        > .e-course-curriculum-illust2 {
          position: absolute;
          width: ${rem(112.62 / 2)};
          right: ${rem(-50 / 2)};
          bottom: ${rem(50 / 2)};
          opacity: 0;
          transform: translateY(${rem(20)});

          @media ${breakpoints.lg} {
            width: ${rem(90.1)};
            right: ${rem(-120)};
            bottom: ${rem(15)};
          }
        }
      }

      > .e-course-curriculum-list {
        display: flex;
        flex-direction: column;
        gap: ${rem(30 / 2)};
        list-style: none;
        width: ${rem(560 / 2)};
        margin: ${rem(50 / 2)} auto 0 auto;

        @media ${breakpoints.lg} {
          width: ${rem(900)};
          margin: ${rem(44)} auto 0 auto;
          flex-direction: row;
          gap: ${rem(40)};
        }

        > .e-course-curriculum-list-item {
          color: #000;
          font-size: ${rem(36 / 2)};
          font-family: ${fontFamily.zenKaku};
          font-weight: bold;
          line-height: calc(68 / 36);
          margin: 0;
          padding: ${rem(11 / 2)} ${rem(33 / 2)};
          border-radius: ${rem(10 / 2)};
          background-color: #fff0f0;

          @media ${breakpoints.lg} {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            font-size: ${rem(24)};
            border-radius: ${rem(10)};
          }

          > .e-course-curriculum-list-item-number {
            font-size: ${rem(52 / 2)};
            font-family: ${fontFamily.montserrat};
            font-weight: 600;
            line-height: calc(68 / 52);
            color: #ff1a56;
            margin-right: ${rem(7 / 2)};

            @media ${breakpoints.lg} {
              font-size: ${rem(42)};
              margin-right: ${rem(5 / 8)};
            }
          }
        }
      }

      > .e-course-curriculum-item-head {
        position: relative;
        width: ${rem(620 / 2)};
        margin: ${rem(100 / 2)} auto 0 auto;
        text-align: center;
        color: #0028be;
        background-color: #fff0f0;
        font-size: ${rem(42 / 2)};
        padding: ${rem(40 / 2)} 0 ${rem(30 / 2)} 0;
        line-height: calc(58 / 42);
        font-family: ${fontFamily.zenKaku};
        border-top: solid #000 ${rem(2)};
        border-bottom: solid #000 ${rem(2)};

        @media ${breakpoints.lg} {
          width: ${rem(900)};
          margin: ${rem(89)} auto 0 auto;
          font-size: ${rem(32)};
          padding: ${rem(27)} 0 ${rem(20)} 0;
          border-top: solid #000 ${rem(3)};
          border-bottom: solid #000 ${rem(3)};
        }

        > .is-small {
          font-size: ${rem(36 / 2)};
          line-height: calc(58 / 36);

          @media ${breakpoints.lg} {
            font-size: ${rem(28)};
            line-height: calc(32 / 24);
          }
        }

        &[data-type='curriculum2'] {
          margin-top: ${rem(150 / 2)};

          @media ${breakpoints.lg} {
            margin-top: ${rem(150)};
          }
        }

        > .e-course-curriculum-item-head-badge {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: ${rem(-40 / 2)};
          left: ${rem(-43 / 2)};
          width: ${rem(108 / 2)};
          height: ${rem(108 / 2)};
          font-family: ${fontFamily.montserrat};
          color: #fff;
          background-color: #ff648c;
          border-radius: 9999px;

          @media ${breakpoints.lg} {
            top: ${rem(-19)};
            left: ${rem(199)};
            width: ${rem(76)};
            height: ${rem(76)};
            font-size: ${rem(18)};
          }

          &[data-type='primary1'] {
            @media ${breakpoints.lg} {
              top: ${rem(-19)};
              left: ${rem(168)};
            }
          }

          &[data-type='primary2'] {
            @media ${breakpoints.lg} {
              top: ${rem(-19)};
              left: ${rem(208)};
            }
          }

          > .e-course-curriculum-item-head-number {
            display: block;
            font-size: ${rem(52 / 2)};
            line-height: 1;

            @media ${breakpoints.lg} {
              font-size: ${rem(36)};
            }
          }
        }
      }

      > .e-course-curriculum1-contents {
        display: flex;
        flex-direction: column;
        color: #000;

        .e-course-curriculum1-contents-top {
          display: flex;
          flex-direction: column;
          gap: ${rem(32 / 2)};
          width: ${rem(560 / 2)};
          margin: ${rem(80 / 2)} auto 0 auto;

          @media ${breakpoints.lg} {
            display: flex;
            flex-direction: row;
            width: ${rem(900)};
            gap: ${rem(46)};
            margin: ${rem(45)}0 0 0;
            padding: 0 ${rem(18)} 0 ${rem(35)};
          }

          .e-course-curriculum1-contents-image {
            flex-shrink: 0;

            @media ${breakpoints.lg} {
              width: ${rem(400)};
            }
          }

          .e-course-curriculum1-contents-text {
            width: ${rem(560 / 2)};
            font-size: ${rem(32 / 2)};
            letter-spacing: 0.06em;
            line-height: calc(58 / 32);
            margin: 0;

            @media ${breakpoints.lg} {
              width: 100%;
              font-size: ${rem(18)};
              line-height: calc(32 / 18);
            }

            > .is-highlight {
              color: #ff1a56;
            }
          }
        }

        .e-course-curriculum1-target {
          margin: ${rem(48 / 2)} auto 0 auto;

          @media ${breakpoints.lg} {
            margin: ${rem(40)} auto 0 auto;
          }
        }

        .e-course-curriculum1-date {
          margin: ${rem(70 / 2)} auto 0 auto;

          @media ${breakpoints.lg} {
            margin: ${rem(60)} auto 0 auto;
          }
        }

        .e-course-curriculum1-time {
          margin: ${rem(58 / 2)} auto 0 auto;

          @media ${breakpoints.lg} {
            margin: ${rem(50)} auto 0 auto;
          }
        }

        .e-course-curriculum1-subject {
          margin: ${rem(70 / 2)} auto 0 auto;
        }

        .e-course-curriculum1-price {
          margin: ${rem(58 / 2)} auto 0 auto;

          .e-course-curriculum1-price-notes {
            display: block;
            margin-top: ${rem(18 / 2)};
            font-size: ${rem(22 / 2)};
            line-height: calc(34 / 22);
            letter-spacing: 0.06em;

            @media ${breakpoints.lg} {
              margin-top: ${rem(13)};
              font-size: ${rem(14)};
            }
          }
        }
      }

      > .e-course-curriculum2-contents {
        color: #000;
        display: flex;
        flex-direction: column;

        .e-course-curriculum2-contents-text {
          width: ${rem(560 / 2)};
          margin: ${rem(32 / 2)} auto 0 auto;
          font-size: ${rem(32 / 2)};
          letter-spacing: 0.06em;
          line-height: calc(58 / 32);

          @media ${breakpoints.lg} {
            width: ${rem(620)};
            margin: ${rem(45)} auto 0 auto;
            font-size: ${rem(18)};
            line-height: calc(32 / 18);
          }

          > .is-highlight {
            color: #ff1a56;
          }
        }

        .e-course-curriculum2-target {
          margin: ${rem(48 / 2)} auto 0 auto;

          @media ${breakpoints.lg} {
            margin: ${rem(42)} auto 0 auto;
          }
        }

        .e-course-curriculum2-experiment {
          margin: ${rem(70 / 2)} auto 0 auto;

          @media ${breakpoints.lg} {
            margin: ${rem(60)} auto 0 auto;
          }
        }

        .e-course-curriculum2-date {
          margin: ${rem(58 / 2)} auto 0 auto;

          @media ${breakpoints.lg} {
            margin: ${rem(48)} auto 0 auto;
          }
        }

        .e-course-curriculum2-price {
          margin: ${rem(70 / 2)} auto 0 auto;

          @media ${breakpoints.lg} {
            margin: ${rem(60)} auto 0 auto;
          }

          .e-course-curriculum2-price-notes {
            width: fit-content;
            list-style: none;
            margin: ${rem(15 / 2)} auto 0 auto;
            font-size: ${rem(22 / 2)};
            letter-spacing: 0.06em;

            @media ${breakpoints.lg} {
              display: flex;
              margin: ${rem(5)} auto 0 auto;
              font-size: ${rem(14)};
            }

            .e-course-curriculum2-price-notes-item {
              margin: 0;
            }
          }
        }
      }

      .e-course-curriculums-contents-head {
        display: flex;
        justify-content: center;
        align-items: center;
        width: ${rem(620 / 2)};
        margin: 0 auto;

        @media ${breakpoints.lg} {
          width: ${rem(900)};
        }

        .e-course-curriculums-contents-head-text {
          display: flex;
          justify-content: center;
          align-items: center;

          min-width: ${rem(300 / 2)};
          height: ${rem(64 / 2)};

          text-align: center;
          color: #0028be;
          font-size: ${rem(42 / 2)};
          font-family: ${fontFamily.zenKaku};
          font-weight: bold;

          border: ${rem(2)} solid #000;
          border-radius: 9999px;
          background-color: #fff0f0;

          @media ${breakpoints.lg} {
            min-width: ${rem(250)};
            height: ${rem(51)};
            font-size: ${rem(30)};
            border: ${rem(3)} solid #000;
          }
        }

        &::before,
        &::after {
          display: block;
          content: '';
          width: 100%;
          height: ${rem(2)};
          background-color: #000;

          @media ${breakpoints.lg} {
            height: ${rem(3)};
          }
        }
      }

      .e-course-curriculums-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        list-style: none;
        margin: ${rem(30 / 2)} auto 0 auto;

        @media ${breakpoints.lg} {
          flex-direction: row;
          gap: ${rem(60)};
        }

        &[data-row='3'] {
          .e-course-curriculums-list-item {
            @media ${breakpoints.lg} {
              width: 100%;
            }
          }
        }

        .e-course-curriculums-list-item {
          display: flex;
          flex-direction: column;
          margin: 0;

          @media ${breakpoints.lg} {
            width: ${rem(380)};
          }

          &:not(:first-child) {
            margin-top: ${rem(25 / 2)};

            @media ${breakpoints.lg} {
              margin-top: ${rem(0)};
            }
          }

          &[data-type='price'] {
            &:not(:first-child) {
              margin-top: ${rem(50 / 2)};

              @media ${breakpoints.lg} {
                margin-top: ${rem(0)};
              }
            }
          }

          .e-course-curriculums-list-item-head {
            display: flex;
            justify-content: center;
            align-items: center;

            color: #ff1a56;
            font-size: ${rem(28 / 2)};
            padding: ${rem(12 / 2)} 0;
            letter-spacing: 0.06em;
            background-color: #fff0f0;

            @media ${breakpoints.lg} {
              font-size: ${rem(18)};
              padding: ${rem(12)} 0;
            }
          }

          .e-course-curriculums-list-item-contents {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: ${rem(28 / 2)};
            padding: ${rem(12 / 2)} 0;
            letter-spacing: 0.06em;

            @media ${breakpoints.lg} {
              font-size: ${rem(18)};
              padding: ${rem(12)} 0;
            }

            &[data-type='price'] {
              justify-content: flex-start;
              padding-left: ${rem(184 / 2)};
              border-bottom: ${rem(1)} solid #adadad;

              @media ${breakpoints.lg} {
                padding-left: ${rem(50)};
              }
            }

            > .is-bold {
              font-weight: bold;
            }
          }
        }
      }

      .e-course-curriculums-text {
        text-align: center;
        margin: ${rem(32 / 2)} 0 0 0;
        font-size: ${rem(28 / 2)};

        @media ${breakpoints.lg} {
          margin: ${rem(22)} 0 0 0;
          font-size: ${rem(18)};
        }

        > .is-bold {
          font-weight: bold;
        }
      }
    }

    > .e-course-overview-close {
      margin: ${rem(60 / 2)} auto 0 auto;
      color: #000;

      @media ${breakpoints.lg} {
        width: ${rem(400)};
        margin: ${rem(60)} auto 0 auto;
      }

      > .e-course-overview-close-text {
        font-size: ${rem(36 / 2)};
        text-align: center;
        margin: 0;
        font-family: ${fontFamily.zenKaku};
        font-weight: bold;

        @media ${breakpoints.lg} {
          font-size: ${rem(32)};
        }
      }

      > .e-course-overview-close-arrow {
        width: ${rem(410 / 2)};
        height: ${rem(12.79 / 2)};
        margin: ${rem(13.5 / 2)} auto 0 auto;

        @media ${breakpoints.lg} {
          width: ${rem(339.54)};
          height: ${rem(10.6)};
          margin: ${rem(13.5)} auto 0 auto;
        }
      }

      > .e-course-overview-close-date {
        font-size: ${rem(42 / 2)};
        text-align: center;
        margin: ${rem(12.7 / 2)} 0 0 0;
        color: #0028be;
        font-family: ${fontFamily.zenKaku};
        font-weight: bold;

        @media ${breakpoints.lg} {
          font-size: ${rem(38)};
          margin: 0;
        }
      }

      > .e-course-overview-close-notes {
        list-style: none;
        margin: ${rem(22 / 2)} auto 0 auto;
        width: ${rem(590 / 2)};

        @media ${breakpoints.lg} {
          width: 100%;
          margin: ${rem(24)} auto 0 auto;
        }

        > .e-course-overview-close-notes-item {
          font-size: ${rem(22 / 2)};
          margin: 0;

          @media ${breakpoints.lg} {
            font-size: ${rem(14)};
          }
        }
      }
    }

    > .e-cta {
      width: 100vw;
      margin: ${rem(100 / 2)} calc(50% - 50vw) 0;

      @media ${breakpoints.lg} {
        margin: ${rem(100)} calc(50% - 50vw) 0;
      }
    }

    @keyframes slideFadeIn {
      0% {
        opacity: 0;
        transform: translateY(${rem(20)});
      }

      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
`

export const CoursePrimary: (props: CoursePrimaryProps) => JSX.Element = StyledComponent

export default CoursePrimary
