import React from 'react'
import styled from 'styled-components'
import { rem, springColor, fontFamily, breakpoints } from 'variables/_index'
import classNames from 'classnames'
import { StaticImage } from 'gatsby-plugin-image'

export interface CourseTabProps {
  readonly gradeImage: JSX.Element
  readonly gradeText: string
  readonly isCurrent?: boolean
  readonly onClick: () => void
}

type CourseTabPropsClassName = CourseTabProps & {
  readonly className: string
}

function Component({ className, gradeText, isCurrent = false, onClick }: CourseTabPropsClassName) {
  return (
    <div className={classNames(className, isCurrent && 'is-current')} onClick={onClick}>
      <div className="e-course-content">
        <div className="e-course-title">
          {gradeText}
          <br className="is-sp" />
          コース
        </div>
        <div className="e-course-arrow">
          <StaticImage src="../../../../assets/images/spring/course/course_tab_arrow.svg" alt="" placeholder="none" />
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<CourseTabPropsClassName>`
  cursor: pointer;
  border-radius: 10px 10px 0 0;
  position: relative;

  @media ${breakpoints.lg} {
    border-radius: 20px 20px 0 0;
  }

  @media ${breakpoints.lg} {
    &:hover {
      > .e-course-content {
        > .e-course-arrow {
          transform: translate(0, 5px);
        }
      }
    }
  }

  &.is-current {
    &:hover {
      > .e-course-content {
        > .e-course-arrow {
          transform: translate(0);
        }
      }
    }
    > .e-course-content {
      background-color: #ade2ff;
      padding-top: ${rem(32 / 2)};
      border: ${rem(2)} solid #000000;
      border-bottom: none;
      z-index: 1;

      @media ${breakpoints.lg} {
        border: ${rem(3)} solid #000000;
        border-bottom: none;
        padding-top: ${rem(40)};
      }

      > .e-course-title {
        font-size: ${rem(42 / 2)};

        @media ${breakpoints.lg} {
          font-size: ${rem(32)};
        }
      }
    }
  }

  > .e-course-content {
    padding: ${rem(20 / 2)} ${rem(10 / 2)};
    border-radius: inherit;
    background-color: #e1ebf0;
    text-align: center;
    position: relative;
    border-bottom: none;
    z-index: -1;
    transition: background-color 0.3s ease 0s;
    cursor: pointer;
    border: ${rem(3 / 2)} solid #e1ebf0;

    @media ${breakpoints.lg} {
      border: ${rem(3)} solid #e1ebf0;
      padding-top: ${rem(30)};
      padding-bottom: ${rem(18)};
      border-width: 3px;
    }

    > .e-course-image {
      img {
        @media ${breakpoints.lg} {
          width: ${rem(148)};
        }
      }
    }

    > .e-course-title {
      font-size: ${rem(36 / 2)};
      line-height: 1.1;
      letter-spacing: 0.1em;
      font-weight: bold;
      font-family: ${fontFamily.zenKaku};
      color: #000;
      @media ${breakpoints.lg} {
        font-size: ${rem(28)};

        > .is-sp {
          display: none;
        }
      }
    }

    > .e-course-arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.2s;
      width: ${rem(20.42 / 2)};
      height: ${rem(12 / 2)};
      margin: ${rem(16 / 2)} auto 0 auto;

      @media ${breakpoints.lg} {
        width: ${rem(20.42)};
        height: ${rem(12)};
        margin: ${rem(16)} auto 0 auto;
      }
    }
  }
`

export const CourseTab: (props: CourseTabProps) => JSX.Element = StyledComponent

export default CourseTab
