import React from 'react'
import styled from 'styled-components'
import { rem, breakpoints, innerSpringWidth, fontFamily, springColor } from 'variables/_index'
import { StaticImage } from 'gatsby-plugin-image'
import { useInView } from 'react-intersection-observer'

export interface StyleSectionProps {
  readonly noProps?: boolean
}

type StyleSectionPropsClassName = StyleSectionProps & {
  readonly className: string
}

function Component({ className }: StyleSectionPropsClassName) {
  const { ref, inView } = useInView({
    rootMargin: '-100px', // ref要素が現れてから100px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  })

  return (
    <div className={className}>
      <div className="e-inner">
        <h2 className="e-style-head">
          <span className="e-style-head-jp">受講方法</span>
          <span className="e-style-head-en">how to</span>
        </h2>
        <div className="e-style-lead">
          <p className="e-style-lead-text">
            インターネット環境があれば
            <br className="u-hidden-pc" />
            ご受講が可能です。
          </p>
          <div className="e-style-lead-imageWrap">
            <StaticImage className="e-style-lead-image" src="../../../assets/images/spring/style/style_lead_img.png" alt="" placeholder="none" />
          </div>
        </div>

        <div className="e-style-boxes">
          <div className="e-style-box">
            <h3 className="e-style-box-head">授業の受け方</h3>
            <p className="e-style-box-text">
              たったの2ステップで
              <br className="u-hidden-pc" />
              簡単に授業を受けられます。
            </p>
            <div className="e-style-box1-content">
              <div className="e-style-box1-left">
                <p className="e-style-box1-number">1</p>
                <p className="e-style-box1-lead">
                  学習システムにログインし、
                  <br />
                  授業の予定をカレンダーで確認
                </p>
                <div className="e-style-box1-image">
                  <StaticImage src="../../../assets/images/spring/style/style_box_img01.png" alt="" placeholder="none" />
                </div>
              </div>
              <div className="e-style-box1-right">
                <p className="e-style-box1-number">2</p>
                <p className="e-style-box1-lead e-style-box1-lead2">授業の入室ボタンをクリック</p>
                <div className="e-style-box1-flexWrap">
                  <div className="e-style-box1-image">
                    <StaticImage src="../../../assets/images/spring/style/style_box_img02.png" alt="" placeholder="none" />
                  </div>
                  <div className="e-style-box1-text">Zoomで授業スタート!</div>
                </div>
              </div>
            </div>
          </div>
          <div className="e-style-box">
            <h3 className="e-style-box-head">利用可能端末</h3>
            <div className="e-style-box2-content">
              <p className="e-style-box2-text">スマホ以外ほぼすべての端末が利用できます。</p>

              <div className="e-style-box2-device">
                <p className="e-style-box2-device-text">
                  タブレット<span className="is-small">（アンドロイドまたはiPad）</span>
                </p>
                <p className="e-style-box2-device-text">
                  PC<span className="is-small">（WindowsまたはMac）</span>
                </p>
              </div>

              <p className="e-style-box2-notes">&#8251;小学生の無料体験はスマホOK!</p>
            </div>
          </div>
          <div className="e-style-box">
            <h3 className="e-style-box-head">端末レンタルについて</h3>
            <div ref={ref} className="e-style-box3-content" data-anim={inView}>
              <p className="e-style-box3-text">タブレットのレンタルもございます。無料体験時にご相談ください。</p>
              <div className="e-style-box3-illust">
                <StaticImage src="../../../assets/images/spring/style/style_box_img05.png" alt="" placeholder="none" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<StyleSectionPropsClassName>`
  margin-left: auto;
  margin-right: auto;
  padding: ${rem(120 / 2)} 0 ${rem(100 / 2)} 0;

  @media ${breakpoints.lg} {
    padding: ${rem(100)} 0;
  }

  > .e-inner {
    width: ${innerSpringWidth.sm};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media ${breakpoints.lg} {
      width: ${rem(1040)};
      padding-left: ${rem(20)};
      padding-right: ${rem(20)};
    }

    > .e-style-head {
      text-align: center;
      display: flex;
      flex-direction: column;

      > .e-style-head-jp {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: ${rem(68 / 2)};
        font-family: ${fontFamily.zenKaku};
        color: #0028be;

        @media ${breakpoints.lg} {
          font-size: ${rem(54)};
          font-weight: bold;
        }

        &::after {
          display: block;
          content: '';
          width: ${rem(12 / 2)};
          height: ${rem(12 / 2)};
          margin-top: ${rem(12 / 2)};
          border-radius: ${rem(3)};
          background-color: #ff1a56;

          @media ${breakpoints.lg} {
            width: ${rem(10)};
            height: ${rem(10)};
            margin-top: ${rem(10)};
            border-radius: ${rem(5)};
          }
        }
      }

      > .e-style-head-en {
        font-family: ${fontFamily.montserrat};
        color: #acacac;
        font-size: ${rem(38 / 2)};
        margin-top: ${rem(15 / 2)};

        @media ${breakpoints.lg} {
          margin-top: ${rem(11.8)};
          font-size: ${rem(30)};
          font-weight: 600;
          letter-spacing: 0.2em;
        }
      }
    }

    > .e-style-lead {
      margin-bottom: ${rem(60 / 2)};

      @media ${breakpoints.lg} {
        margin-bottom: ${rem(64)};
      }

      > .e-style-lead-text {
        color: #000;
        font-size: ${rem(36 / 2)};
        font-weight: 500;
        text-align: center;
        line-height: 1.6;
        margin: ${rem(31.5 / 2)} 0 0 0;
        letter-spacing: 0.1em;

        @media ${breakpoints.lg} {
          font-size: ${rem(24)};
          margin: ${rem(60.4)} 0 0 0;
          letter-spacing: 0.05em;
        }
      }

      > .e-style-lead-imageWrap {
        width: ${rem(550 / 2)};
        margin: ${rem(31.5 / 2)} auto 0 auto;

        @media ${breakpoints.lg} {
          width: ${rem(525)};
          margin: ${rem(21)} auto 0 auto;
        }
      }
    }

    > .e-style-boxes {
      margin-left: ${rem(-10 / 2)};
      margin-right: ${rem(-10 / 2)};

      @media ${breakpoints.lg} {
        margin-left: 0;
        margin-right: 0;
      }

      > .e-style-box {
        color: #000;
        border-radius: ${rem(20 / 2)};
        border: 2px solid ${springColor.fitMain};
        overflow: hidden;

        @media ${breakpoints.lg} {
          border-radius: ${rem(20)};
          border-width: 3px;
        }

        &:nth-child(n + 2) {
          margin-top: ${rem(64 / 2)};

          @media ${breakpoints.lg} {
            margin-top: ${rem(64)};
          }
        }

        > .e-style-box-head {
          text-align: center;
          padding: ${rem(40 / 2)} ${rem(10 / 2)};
          font-family: ${fontFamily.zenKaku};
          line-height: 1;
          letter-spacing: 0.1em;
          font-size: ${rem(46 / 2)};
          font-weight: 700;
          color: #000;
          border-bottom: 1px solid ${springColor.fitMain};
          background-color: #ddf3ff;

          @media ${breakpoints.lg} {
            padding: ${rem(16)};
            font-size: ${rem(38)};
          }
        }

        > .e-style-box-text {
          font-size: ${rem(32 / 2)};
          font-weight: 500;
          line-height: 1.6;
          text-align: center;
          margin-top: ${rem(64 / 2)};
          margin-bottom: ${rem(32 / 2)};
          letter-spacing: 0.1em;

          @media ${breakpoints.lg} {
            font-size: ${rem(24)};
            line-height: 1;
            margin-top: ${rem(32)};
            margin-bottom: ${rem(24)};
          }
        }

        > .e-style-box1-content {
          display: flex;
          flex-wrap: wrap;
          padding: 0 ${rem(60 / 2)} ${rem(80 / 2)};

          @media ${breakpoints.lg} {
            padding: 0 ${rem(40)} ${rem(48)};
          }

          > .e-style-box1-left,
          > .e-style-box1-right {
            width: 100%;
            padding-left: ${rem(0)};
            padding-right: ${rem(0)};

            @media ${breakpoints.lg} {
              width: 50%;
              padding-left: ${rem(40)};
              padding-right: ${rem(40)};
            }
          }

          > .e-style-box1-left {
          }

          > .e-style-box1-right {
            margin-top: ${rem(56 / 2)};

            @media ${breakpoints.lg} {
              margin-top: ${rem(0)};
            }
          }

          .e-style-box1-number {
            width: ${rem(448 / 2)};
            margin: 0 auto;
            font-family: ${fontFamily.zenKaku};
            font-size: ${rem(80 / 2)};
            font-weight: 500;
            line-height: 1;
            border-bottom: 2px solid ${springColor.fitMain};
            text-align: center;
            color: ${springColor.fitMain};
            padding-bottom: ${rem(8)};

            @media ${breakpoints.lg} {
              width: ${rem(336)};
              font-size: ${rem(64)};
              padding-bottom: ${rem(12)};
              border-bottom-width: 2px;
            }
          }

          .e-style-box1-lead {
            margin-top: ${rem(32 / 2)};
            margin-bottom: ${rem(32 / 2)};
            font-size: ${rem(32 / 2)};
            font-weight: 500;
            line-height: 1.6;
            text-align: center;
            color: ${springColor.text};
            letter-spacing: 0.1em;

            @media ${breakpoints.lg} {
              margin-top: ${rem(24)};
              margin-bottom: ${rem(32)};
              font-size: ${rem(22)};
              line-height: 1.8;
            }
          }

          .e-style-box1-lead2 {
            @media ${breakpoints.lg} {
              margin-bottom: ${rem(0)};
            }
          }

          .e-style-box1-flexWrap {
            display: flex;
            flex-direction: column;

            @media ${breakpoints.lg} {
              flex-direction: column-reverse;
            }
          }

          .e-style-box1-image {
            width: 100%;
            margin: 0 auto;
            text-align: center;

            @media ${breakpoints.lg} {
              width: ${rem(380)};
            }

            img {
              max-width: 100%;
              margin: 0 auto;
            }
          }

          .e-style-box1-text {
            margin-top: ${rem(24 / 2)};
            margin-bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: ${rem(36 / 2)};
            font-weight: 500;
            line-height: 1;
            letter-spacing: 0.1em;

            @media ${breakpoints.lg} {
              margin-top: ${rem(12)};
              margin-bottom: ${rem(28)};
              font-size: ${rem(24)};
            }

            &::before,
            &::after {
              content: '';
              width: 2px;
              height: ${rem(40 / 2)};
              background-color: ${springColor.text};
              flex-shrink: 0;

              @media ${breakpoints.lg} {
                height: ${rem(30)};
                border-width: 2px;
              }
            }

            &::before {
              margin-right: ${rem(14)};
              transform: rotate(-20deg);

              @media ${breakpoints.lg} {
                margin-right: ${rem(20)};
              }
            }

            &::after {
              margin-left: ${rem(14)};
              transform: rotate(20deg);

              @media ${breakpoints.lg} {
                margin-left: ${rem(20)};
              }
            }
          }
        }

        > .e-style-box2-content {
          display: grid;
          grid-template-areas: 'text' 'device' 'notes';
          padding: 0 ${rem(60 / 2)} ${rem(60 / 2)} ${rem(60 / 2)};

          @media ${breakpoints.lg} {
            padding: 0 ${rem(80)} ${rem(60)} ${rem(80)};
            grid-template-areas: 'text' 'notes' 'device';
          }

          > .e-style-box2-text {
            margin: ${rem(60 / 2)} auto 0 auto;
            grid-area: text;

            @media ${breakpoints.lg} {
              width: ${rem(400)};
              margin: ${rem(40)} auto 0 auto;
              font-size: ${rem(18)};
            }
          }

          > .e-style-box2-notes {
            font-size: ${rem(26 / 2)};
            grid-area: notes;
            margin: ${rem(20 / 2)} 0 0 0;

            @media ${breakpoints.lg} {
              width: ${rem(400)};
              font-size: ${rem(16)};
              margin: ${rem(10)} auto 0 auto;
            }
          }

          > .e-style-box2-device {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: ${rem(16 / 2)};
            grid-area: device;
            flex-direction: column;
            margin: ${rem(32 / 2)} 0 0 0;

            @media ${breakpoints.lg} {
              gap: ${rem(50)};
              margin: ${rem(34)} 0 0 0;
              flex-direction: row;
            }

            > .e-style-box2-device-text {
              text-align: center;
              width: 100%;
              margin: 0;
              padding: ${rem(18 / 2)} 0 ${rem(18 / 2)} 0;
              font-size: ${rem(32 / 2)};
              font-weight: 500;
              color: #fff;
              border-radius: ${rem(16)};
              background-color: #00c8ff;

              @media ${breakpoints.lg} {
                max-width: ${rem(400)};
                font-size: ${rem(24)};
                border-radius: ${rem(10)};
                padding: ${rem(10)} 0 ${rem(10)} 0;
              }
              > .is-small {
                font-size: ${rem(28 / 2)};

                @media ${breakpoints.lg} {
                  font-size: ${rem(18)};
                }
              }
            }
          }
        }

        > .e-style-box3-content {
          padding: ${rem(60 / 2)} ${rem(60 / 2)} ${rem(56 / 2)};

          @media ${breakpoints.lg} {
            display: flex;
            flex-direction: column;
            padding: ${rem(40)} 0 ${rem(50)} 0;
          }

          &[data-anim='true'] {
            > .e-style-box3-illust {
              @media ${breakpoints.lg} {
                animation: slideFadeIn 1s 0s forwards;
              }
            }
          }

          > .e-style-box3-text {
            margin: 0 auto;
            font-size: ${rem(32 / 2)};

            @media ${breakpoints.lg} {
              width: ${rem(557)};
              font-size: ${rem(18)};
            }
          }

          > .e-style-box3-illust {
            display: none;

            @media ${breakpoints.lg} {
              display: block;
              width: ${rem(230)};
              margin: ${rem(32)} auto 0 auto;
              opacity: 0;
              transform: translateY(${rem(20)});
            }
          }
        }
      }
    }

    @keyframes slideFadeIn {
      0% {
        opacity: 0;
        transform: translateY(${rem(20)});
      }

      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
`

export const StyleSection: (props: StyleSectionProps) => JSX.Element = StyledComponent

export default StyleSection
