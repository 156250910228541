import React from 'react'
import styled from 'styled-components'
import MainVisualSection from 'components/section/spring/mainVisualSection'
import VoiceSection from 'components/section/spring/voiceSection'
import ContactSection from 'components/section/spring/contactSection'
import CourseSection from 'components/section/spring/courseSection'
import FlowSection from 'components/section/spring/flowSection'
import StyleSection from 'components/section/spring/styleSection'
import AboutSection from 'components/section/spring/aboutSection'
import LinkSection from 'components/section/spring/linkSection'
import ContactGirl from 'components/section/spring/atom/contactGirl'
import { StaticImage } from 'gatsby-plugin-image'
import CloseSection from 'components/section/spring/closeSection'
import { breakpoints, rem } from 'variables/_index'

export interface SpringTemplateProps {
  readonly noProps?: string
}

type SpringTemplatePropsClassName = SpringTemplateProps & {
  readonly className: string
}

function Component({ className }: SpringTemplatePropsClassName) {
  return (
    <div className={className}>
      <div className="e-main-visual">
        <MainVisualSection />
      </div>
      <div className="e-announce">
        お申し込みは終了しました。
        <br />
        ※2025年度の春期講習の詳細が決まり次第
        <br className="u-hidden-pc" />
        ページ更新いたします。
      </div>

      <div className="e-contact">
        <ContactSection
          type="junior"
          balloonImageElement={<StaticImage src="../assets/images/illustration/illustration16-2.png" alt="" placeholder="none" />}
          text={false}
        />
      </div>

      <div className="e-voice">
        <VoiceSection />
      </div>

      <div className="e-about">
        <AboutSection />
      </div>

      <div className="e-contact">
        <ContactSection
          type="junior"
          balloonImageElement={<StaticImage src="../assets/images/illustration/illustration16-2.png" alt="" placeholder="none" />}
          text={true}
        />
      </div>

      <div className="e-course">
        <CourseSection />
      </div>

      <div className="e-flow">
        <FlowSection />
      </div>

      <div className="e-style">
        <StyleSection />
      </div>

      <div className="e-contact">
        <ContactSection
          type="junior"
          balloonImageElement={<StaticImage src="../assets/images/illustration/illustration16-4.png" alt="" placeholder="none" />}
          text={true}
        />
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<SpringTemplatePropsClassName>`
  > .e-announce {
    margin: ${rem(22)} 0 ${rem(16)};
    line-height: 1.6;
    text-align: center;
    font-weight: bold;
    font-size: ${rem(18)};
    color: red;
    padding-left: ${rem(12)};
    padding-right: ${rem(12)};

    @media ${breakpoints.lg} {
      font-size: ${rem(24)};
      margin: ${rem(30)} 0 ${rem(50)};
    }
  }

  > .e-voice {
    margin-top: ${rem(0)};
    position: relative;
    z-index: 2;

    @media ${breakpoints.lg} {
      margin-top: ${rem(0)};
    }
  }
`

export const SpringTemplate: (props: SpringTemplateProps) => JSX.Element = StyledComponent

export default SpringTemplate
