import { StaticImage } from 'gatsby-plugin-image'
import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { rem, breakpoints, innerWidth, fontFamily, springColor } from 'variables/_index'
import { Pagination, Navigation, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css/autoplay'
import VoiceCard from '../../section/spring/atom/voiceCard'
import ArrowNextImage from 'assets/images/spring/voice/slide-right.svg'
import ArrowPrevImage from 'assets/images/spring/voice/slide-left.svg'
import ArrowNextImagePC from 'assets/images/spring/voice/slide-right-pc.svg'
import ArrowPrevImagePC from 'assets/images/spring/voice/slide-left-pc.svg'
import QuoteImage from 'assets/images/spring/voice/quote.svg'
import { useInView } from 'react-intersection-observer'

export interface VoiceSectionProps {
  readonly noProps?: string
}

type VoiceSectionPropsClassName = VoiceSectionProps & {
  readonly className: string
}

function Component({ className }: VoiceSectionPropsClassName) {
  const { ref, inView } = useInView({
    rootMargin: '-120px', // ref要素が現れてから120px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  })

  return (
    <div className={className}>
      <h2 className="e-head" ref={ref} data-anim={inView}>
        <span className="e-text-line1">
          <StaticImage src="../../../assets/images/spring/voice/voice-logo.png" alt="Fit NET STUDY" placeholder="none" />で
        </span>
        <span className="e-text-line2">こんなに点数</span>
        <span className="e-text-line3">あがっています</span>

        <span className="e-head-arrow">
          <StaticImage src="../../../assets/images/spring/head-arrow.png" alt="" />
        </span>
      </h2>
      <div className="e-voice-area">
        <div className="e-voice-slider">
          <Swiper
            modules={[Pagination, Navigation, Autoplay]}
            spaceBetween={30}
            slidesPerView={1.44}
            centeredSlides={true}
            pagination={{ clickable: true, type: 'fraction' }}
            navigation={true}
            loop={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
                centeredSlides: false,
              },
            }}
          >
            <SwiperSlide>
              <VoiceCard
                score="21"
                subject="数学"
                imageElement={<StaticImage src="../../../assets/images/spring/voice/student01.png" alt="" placeholder="none" />}
                grade="中学2年生"
                name="H.Sさん"
                text={<>講師がアドバイスくれるので自信に繋がってる</>}
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceCard
                score="23"
                subject="数学"
                imageElement={<StaticImage src="../../../assets/images/spring/voice/student02.png" alt="" placeholder="none" />}
                grade="中学2年生"
                name="Y.Tさん"
                text={<>効率よく予習復習の勉強ができるようになった!</>}
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceCard
                score="32"
                subject="数学"
                imageElement={<StaticImage src="../../../assets/images/spring/voice/student03.png" alt="" placeholder="none" />}
                grade="中学2年生"
                name="K.Iさん"
                text={<>先生が自分に寄り添いながら教えてくれる!</>}
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceCard
                score="32"
                subject="数学"
                imageElement={<StaticImage src="../../../assets/images/spring/voice/student04.png" alt="" placeholder="none" />}
                grade="中学3年生"
                name="Y.Kさん"
                text={<>学校と違って質問したらみっちり指導してもらえる!</>}
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceCard
                score="29"
                subject="数学"
                imageElement={<StaticImage src="../../../assets/images/spring/voice/student05.png" alt="" placeholder="none" />}
                grade="中学2年生"
                name="S.Tさん"
                text={<>重点的に学習内容をみてくれるから安心!</>}
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceCard
                score="36"
                subject="数学"
                imageElement={<StaticImage src="../../../assets/images/spring/voice/student06.png" alt="" placeholder="none" />}
                grade="中学2年生"
                name="K.Iさん"
                text={<>講師の解説がわかりやすい!</>}
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceCard
                score="32"
                subject="数学"
                imageElement={<StaticImage src="../../../assets/images/spring/voice/student07.png" alt="" placeholder="none" />}
                grade="中学2年生"
                name="M.Dさん"
                text={<>学校の授業を受けるのが最近楽しくなってきている!</>}
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceCard
                score="29"
                subject="数学"
                imageElement={<StaticImage src="../../../assets/images/spring/voice/student08.png" alt="" placeholder="none" />}
                grade="中学3年生"
                name="S.Iさん"
                text={<>授業中に自分の間違いに気づくことができた!</>}
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceCard
                score="26"
                subject="数学"
                imageElement={<StaticImage src="../../../assets/images/spring/voice/student09.png" alt="" placeholder="none" />}
                grade="中学1年生"
                name="H.Hさん"
                text={<>Fitの先生の教え方がわかりやすい!</>}
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceCard
                score="24"
                subject="数学"
                imageElement={<StaticImage src="../../../assets/images/spring/voice/student10.png" alt="" placeholder="none" />}
                grade="中学3年生"
                name="R.Hさん"
                text={<>いつでも直接教えてもらえるところがいい!</>}
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceCard
                score="35"
                subject="英語"
                imageElement={<StaticImage src="../../../assets/images/spring/voice/student11.png" alt="" placeholder="none" />}
                grade="中学3年生"
                name="N.Oさん"
                text={<>どんなに簡単な問題でも、しっかり教えてくれる!</>}
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceCard
                score="53"
                subject="数学"
                imageElement={<StaticImage src="../../../assets/images/spring/voice/student12.png" alt="" placeholder="none" />}
                grade="中学3年生"
                name="K.Iさん"
                text={<>自分のペースに合わせてやってくれる!</>}
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceCard
                score="29"
                subject="数学"
                imageElement={<StaticImage src="../../../assets/images/spring/voice/student13.png" alt="" placeholder="none" />}
                grade="中学2年生"
                name="K.Nさん"
                text={<>先生の教え方がわかりやすく、学びやすい!</>}
              />
            </SwiperSlide>
            <SwiperSlide>
              <VoiceCard
                score="54"
                subject="数学"
                imageElement={<StaticImage src="../../../assets/images/spring/voice/student14.png" alt="" placeholder="none" />}
                grade="中学3年生"
                name="K.Kさん"
                text={<>個別なので、分からない問題がすぐ聞ける</>}
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<VoiceSectionPropsClassName>`
  background-color: ${springColor.fitMain};
  padding-top: ${rem(120 / 2)};
  padding-bottom: ${rem(100 / 2)};

  @media ${breakpoints.lg} {
    padding-top: ${rem(94)};
    padding-bottom: ${rem(70)};
  }

  > .e-head {
    position: relative;
    width: fit-content;
    margin: 0 auto;
    font-family: ${fontFamily.zenKaku};
    font-weight: 700;
    color: ${springColor.accent2};

    &[data-anim='true'] {
      > .e-text-line1,
      .e-text-line2,
      .e-text-line3 {
        animation: slideUp 0.8s 0s forwards;
      }

      > .e-head-arrow {
        animation: arrowSlide 1.5s 0.4s forwards;
      }
    }

    .e-head-arrow {
      position: absolute;
      width: ${rem(200)};
      right: ${rem(-75)};
      top: ${rem(45)};
      rotate: -45deg;
      opacity: 0;
      transform: translateX(${rem(-50)});

      @media ${breakpoints.lg} {
        width: ${rem(330)};
        right: ${rem(-120)};
        top: ${rem(75)};
      }
    }

    @keyframes arrowSlide {
      0% {
        opacity: 0;
        transform: translateX(${rem(-50)});
      }
      100% {
        transform: translateX(${rem(0)});
        opacity: 1;
      }
    }

    .e-text-line1 {
      position: relative;
      z-index: 2;
      display: block;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      font-size: ${rem(36 / 2)};
      letter-spacing: 0.05em;
      transform: translateY(${rem(25)});
      opacity: 0;

      @media ${breakpoints.lg} {
        font-size: ${rem(30)};
      }

      img {
        width: ${rem(323 / 2)};
        margin-right: ${rem(4)};

        @media ${breakpoints.lg} {
          width: ${rem(274.5)};
        }
      }

      > * {
        vertical-align: baseline;
      }
    }

    .e-text-line2,
    .e-text-line3 {
      position: relative;
      z-index: 2;
      display: block;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      background: #fff;
      border-radius: ${rem(10 / 2)};
      letter-spacing: 0.06em;
      font-size: ${rem(62 / 2)};
      padding-left: ${rem(22 / 2)};
      padding-right: ${rem(22 / 2)};

      transform: translateY(${rem(25)});
      opacity: 0;

      @media ${breakpoints.lg} {
        border-radius: ${rem(10)};
        font-size: ${rem(52)};
        padding-left: ${rem(18)};
        padding-right: ${rem(18)};
      }
    }

    .e-text-line2 {
      margin-top: ${rem(24 / 2)};

      @media ${breakpoints.lg} {
        margin-top: ${rem(20)};
      }

      &::before {
        content: '';
        position: absolute;
        width: ${rem(40 / 2)};
        height: ${rem(28 / 2)};
        background-image: url(${QuoteImage});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        top: ${rem(-14 / 2)};
        left: ${rem(-28 / 2)};

        @media ${breakpoints.lg} {
          width: ${rem(34)};
          height: ${rem(23.84)};
          top: ${rem(-17)};
          left: ${rem(-20)};
        }
      }
    }

    .e-text-line3 {
      margin-top: ${rem(10 / 2)};

      @media ${breakpoints.lg} {
        margin-top: ${rem(10)};
      }
    }

    @keyframes slideUp {
      0% {
        transform: translateY(${rem(25)});
        opacity: 0;
      }
      100% {
        transform: translateY(${rem(0)});
        opacity: 1;
      }
    }
  }

  > .e-voice-area {
    margin-top: ${rem(90 / 2)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(92)};
    }

    > .e-voice-text {
      font-size: ${rem(44 / 2)};
      font-weight: 700;
      font-family: ${fontFamily.zenKaku};
      color: #fff;
      text-align: center;
      line-height: 1.8;

      @media ${breakpoints.lg} {
        font-size: ${rem(38)};
      }

      .is-dot {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          width: ${rem(8 / 2)};
          height: ${rem(8 / 2)};
          background-color: currentColor;
          border-radius: 50%;
          top: ${rem(-1)};
          left: 50%;
          transform: translate(-50%);

          @media ${breakpoints.lg} {
            width: ${rem(6)};
            height: ${rem(6)};
            top: ${rem(0)};
          }
        }
      }

      .is-underline {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: ${rem(1)};
          background: currentColor;
          bottom: ${rem(0)};
          left: 50%;
          transform: translate(-50%);

          @media ${breakpoints.lg} {
            height: ${rem(2)};
          }
        }
      }
    }

    > .e-voice-slider {
      margin-top: ${rem(40 / 2)};

      @media ${breakpoints.lg} {
        width: ${rem(1000)};
        margin-top: ${rem(30)};
        margin-left: auto;
        margin-right: auto;
        padding-left: ${rem(30)};
        padding-right: ${rem(30)};
        overflow: hidden;
      }

      .swiper {
        padding-bottom: ${rem(90 / 2)};

        @media ${breakpoints.lg} {
          padding-bottom: ${rem(66)};
          overflow: visible;
        }
      }

      .swiper-slide {
        height: auto;

        > [class^='voiceCard'] {
          height: 100%;
        }
      }

      .swiper-button-prev,
      .swiper-button-next {
        width: ${rem(90 / 2)};
        height: ${rem(90 / 2)};
        margin-top: ${rem(-40)};

        @media ${breakpoints.lg} {
          width: ${rem(60)};
          height: ${rem(60)};
          margin-top: ${rem(-40)};
        }
      }

      .swiper-button-prev::after,
      .swiper-button-next::after {
        display: none;
      }

      .swiper-button-prev {
        background-image: url(${ArrowPrevImage});
        background-repeat: no-repeat;
        background-size: contain;
        left: auto;
        right: calc(50% + 32vw);

        @media ${breakpoints.lg} {
          background-image: url(${ArrowPrevImagePC});
          right: calc(50% + ${rem(440)});
        }
      }

      .swiper-button-next {
        background-image: url(${ArrowNextImage});
        background-repeat: no-repeat;
        background-size: contain;
        right: auto;
        left: calc(50% + 32vw);

        @media ${breakpoints.lg} {
          background-image: url(${ArrowNextImagePC});
          left: calc(50% + ${rem(440)});
        }
      }

      .swiper-pagination {
        bottom: ${rem(0)};
        font-size: ${rem(28 / 2)};
        font-weight: 700;
        color: #fff;
        letter-spacing: 0.06em;

        @media ${breakpoints.lg} {
          font-size: ${rem(16)};
        }
      }

      .swiper-pagination-bullet {
        width: ${rem(24 / 2)};
        height: ${rem(24 / 2)};
        background-color: #fff;
        opacity: 1;
        margin-right: ${rem(6)};
        margin-left: ${rem(6)};

        @media ${breakpoints.lg} {
          width: ${rem(12)};
          height: ${rem(12)};
          margin-right: ${rem(10)};
          margin-left: ${rem(10)};
        }

        &.swiper-pagination-bullet-active {
          background-color: ${springColor.accent2};
        }
      }
    }
  }
`

export const VoiceSection: (props: VoiceSectionProps) => JSX.Element = StyledComponent

export default VoiceSection
