import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { rem, breakpoints } from 'variables/_index'
import { StaticImage } from 'gatsby-plugin-image'

export interface MainVisualSectionProps {
  readonly noProps?: string
}

type MainVisualSectionPropsClassName = MainVisualSectionProps & {
  readonly className: string
}

function Component({ className }: MainVisualSectionPropsClassName) {
  const [currentImage, setCurrentImage] = useState<boolean>(false)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage(state => !state)
    }, 5400)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className={className}>
      <div className="e-main">
        <div className="e-main-inner">
          <div className="e-main-contents">
            <div className="e-main-visual">
              <h1 className="e-main-visual-head">
                <div className="e-main-visual-head-top">
                  <StaticImage src="../../../assets/images/spring/mv/mv-text-top.png" alt="オンライン個別指導の" placeholder="none" loading="eager" />
                </div>

                <div className="e-main-visual-head-main">
                  <div className="e-main-visual-head-text1">
                    <StaticImage src="../../../assets/images/spring/mv/mv-text1.png" alt="春" placeholder="none" loading="eager" />
                  </div>
                  <div className="e-main-visual-head-text2">
                    <StaticImage src="../../../assets/images/spring/mv/mv-text2.png" alt="期" placeholder="none" loading="eager" />
                  </div>
                  <div className="e-main-visual-head-text3">
                    <StaticImage src="../../../assets/images/spring/mv/mv-text3.png" alt="講" placeholder="none" loading="eager" />
                  </div>
                  <div className="e-main-visual-head-text4">
                    <StaticImage src="../../../assets/images/spring/mv/mv-text4.png" alt="習" placeholder="none" loading="eager" />
                  </div>
                </div>
              </h1>

              <div className="e-main-visual-lead">
                <div className="e-main-visual-lead-wrap">
                  <div className="e-main-visual-lead-image">
                    <div className="is-sp">
                      <StaticImage
                        src="../../../assets/images/spring/mv/fv_text_sp.png"
                        alt="”わからない”を克服し新学年で点数アップ！"
                        placeholder="none"
                        loading="eager"
                      />
                    </div>
                    <div className="is-pc">
                      <StaticImage
                        src="../../../assets/images/spring/mv/fv_text_pc.png"
                        alt="”わからない”を克服し新学年で点数アップ！"
                        placeholder="none"
                        loading="eager"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="e-main-visual-background">
                <div className="e-main-visual-background-contents">
                  <div className="e-main-visual-background-image1 is-sp" data-current={currentImage}>
                    <StaticImage src="../../../assets/images/spring/mv/fv_photo1_sp.png" alt="" placeholder="none" loading="eager" />
                  </div>
                  <div className="e-main-visual-background-image2 is-sp" data-current={!currentImage}>
                    <StaticImage src="../../../assets/images/spring/mv/fv_photo2_sp.png" alt="" placeholder="none" loading="eager" />
                  </div>

                  <div className="e-main-visual-background-image1 is-pc" data-current={currentImage}>
                    <StaticImage src="../../../assets/images/spring/mv/fv_photo1_pc.png" alt="" placeholder="none" loading="eager" />
                  </div>
                  <div className="e-main-visual-background-image2 is-pc" data-current={!currentImage}>
                    <StaticImage src="../../../assets/images/spring/mv/fv_photo2_pc.png" alt="" placeholder="none" loading="eager" />
                  </div>
                </div>
              </div>

              <span className="e-main-visual-sp-date">実施期間：3/18(月)ー4/13(土)</span>
              <span className="e-main-visual-sp-subject">小学生｜中学生｜高校生</span>

              <div className="e-main-visual-pc-text">
                <span className="e-main-visual-pc-date">実施期間：3/18(月)ー4/13(土)</span>
                <span className="e-main-visual-pc-subject">小学生｜中学生｜高校生</span>
              </div>

              <div className="e-main-visual-illust">
                <div className="e-main-visual-illust-contents">
                  <div className="e-main-visual-fusen1">
                    <StaticImage src="../../../assets/images/spring/mv/fv_fusen1.png" alt="" placeholder="none" loading="eager" />
                  </div>
                  <div className="e-main-visual-fusen2">
                    <StaticImage src="../../../assets/images/spring/mv/fv_fusen2.png" alt="" placeholder="none" loading="eager" />
                  </div>
                  <div className="e-main-visual-fusen3">
                    <StaticImage src="../../../assets/images/spring/mv/fv_fusen3.png" alt="" placeholder="none" loading="eager" />
                  </div>

                  <div className="e-main-visual-compas">
                    <StaticImage src="../../../assets/images/spring/mv/fv_compas.png" alt="" placeholder="none" loading="eager" />
                  </div>

                  <div className="e-main-visual-pen">
                    <StaticImage src="../../../assets/images/spring/mv/fv_pen.png" alt="" placeholder="none" loading="eager" />
                  </div>

                  <div className="e-main-visual-note">
                    <StaticImage src="../../../assets/images/spring/mv/fv_note.png" alt="" placeholder="none" loading="eager" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="e-main-under">
          <div className="e-main-achievements">
            <div className="e-main-achievements-contents">
              <div className="e-main-achievements-image">
                <StaticImage src="../../../assets/images/spring/mv/achievements1.png" alt="" placeholder="none" loading="eager" />
              </div>
              <div className="e-main-achievements-image">
                <StaticImage src="../../../assets/images/spring/mv/achievements2.png" alt="" placeholder="none" loading="eager" />
              </div>
            </div>
            <p className="e-main-achievements-text">&#8251;運営会社CKCネットワーク株式会社全体の実績です。</p>
          </div>
          <div className="e-main-logo">
            <StaticImage src="../../../assets/images/spring/mv/logo.png" alt="" placeholder="none" loading="eager" />
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<MainVisualSectionPropsClassName>`
  .e-main {
    position: relative;
    background-color: #fe7b9b;

    > .e-main-inner {
      max-width: 1080px;
      margin: 0 auto;

      > .e-main-contents {
        position: relative;
        aspect-ratio: 750/1088;
        max-width: ${rem(750)};
        margin: 0 auto;

        @media ${breakpoints.lg} {
          aspect-ratio: 1080/710;
          max-width: ${rem(1080)};
        }

        > .e-main-visual {
          padding: ${rem(135.5 / 2)} 0 0 0;

          @media ${breakpoints.lg} {
            padding: 0;
          }

          > .e-main-visual-head {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            z-index: 1;

            @media ${breakpoints.lg} {
              width: ${rem(523.42)};
            }

            > .e-main-visual-head-top {
              width: ${rem(483 / 2)};
              height: ${rem(54 / 2)};

              @media ${breakpoints.lg} {
                width: ${rem(404.23)};
                height: ${rem(45.19)};
                margin: ${rem(163.1)} auto 0 auto;
              }
            }
            > .e-main-visual-head-main {
              display: flex;
              justify-content: center;
              width: ${rem(630 / 2)};
              margin: ${rem(20 / 2)} 0 0 0;

              @media ${breakpoints.lg} {
                width: ${rem(523.42)};
                margin: ${rem(18)} 0 0 0;
              }

              > .e-main-visual-head-text1 {
                width: ${rem(148.63 / 2)};
                margin-right: ${rem(10 / 2)};
                opacity: 0;
                animation: fadeIn 1s 1s forwards;

                @media ${breakpoints.lg} {
                  width: ${rem(118.15)};
                  margin-right: ${rem(13.6)};
                }
              }
              > .e-main-visual-head-text2 {
                width: ${rem(146.21 / 2)};
                margin-right: ${rem(22.6 / 2)};
                opacity: 0;
                animation: fadeIn 1s 1.5s forwards;

                @media ${breakpoints.lg} {
                  width: ${rem(117.39)};
                  margin-right: ${rem(22.7)};
                }
              }
              > .e-main-visual-head-text3 {
                width: ${rem(143.34 / 2)};
                margin-right: ${rem(22 / 2)};
                opacity: 0;
                animation: fadeIn 1s 2s forwards;

                @media ${breakpoints.lg} {
                  width: ${rem(115.01)};
                  margin-right: ${rem(22.1)};
                }
              }
              > .e-main-visual-head-text4 {
                width: ${rem(137.32 / 2)};
                opacity: 0;
                animation: fadeIn 1s 2.5s forwards;

                @media ${breakpoints.lg} {
                  width: ${rem(110.01)};
                }
              }
            }
          }

          > .e-main-visual-lead {
            position: absolute;
            width: calc(542 / 750 * 100%);

            bottom: ${rem(128 / 2)};
            right: ${rem(0)};
            z-index: 1;

            @media ${breakpoints.lg} {
              width: ${rem(438)};
              top: ${rem(395)};
              left: ${rem(44)};
            }

            > .e-main-visual-lead-wrap {
              overflow: hidden;
              translate: -100% 0;
              animation: lead linear 1.4s forwards 2s;
              opacity: 0;

              > .e-main-visual-lead-image {
                translate: 100% 0;
                animation: width 1s 3s forwards;

                animation: leadIn linear 1.4s forwards 2s;
              }

              @keyframes lead {
                0% {
                  opacity: 0;
                  translate: -100% 0;
                }

                100% {
                  opacity: 1;
                  translate: 0 0;
                }
              }

              @keyframes leadIn {
                0% {
                  opacity: 0;
                  translate: 100% 0;
                }

                100% {
                  opacity: 1;
                  translate: 0 0;
                }
              }
            }
          }

          > .e-main-visual-background {
            position: absolute;
            top: ${rem(297 / 2)};
            left: 0;
            width: calc(720 / 750 * 100%);
            z-index: 0;

            @media ${breakpoints.lg} {
              width: ${rem(680)};
              top: ${rem(132)};
              left: auto;
              right: 0;
            }

            > .e-main-visual-background-contents {
              position: relative;

              > .e-main-visual-background-image1 {
                transition: 2s;
                &[data-current='false'] {
                  opacity: 0;
                }
                &[data-current='true'] {
                  opacity: 1;
                }
              }
              > .e-main-visual-background-image2 {
                position: absolute;
                top: 0;
                transition: 1.5s;
                &[data-current='false'] {
                  opacity: 0;
                }
                &[data-current='true'] {
                  opacity: 1;
                }
              }
            }
          }

          > .e-main-visual-sp-date {
            width: 100%;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: ${rem(74 / 2)};
            text-align: center;
            font-size: ${rem(32 / 2)};
            letter-spacing: 0.1em;
            font-weight: 500;
            color: #fff;

            @media ${breakpoints.lg} {
              display: none;
            }
          }

          > .e-main-visual-sp-subject {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
            height: ${rem(58 / 2)};
            text-align: center;
            font-size: ${rem(30 / 2)};
            letter-spacing: 0.5em;
            font-weight: 500;
            color: #fff;
            background-color: #ff648c;

            @media ${breakpoints.lg} {
              display: none;
            }
          }

          > .e-main-visual-pc-text {
            display: none;
            position: absolute;
            left: ${rem(44)};
            bottom: ${rem(64)};
            flex-direction: column;
            width: fit-content;
            font-size: ${rem(21)};
            color: #fff;
            font-weight: bold;

            @media ${breakpoints.lg} {
              display: flex;
            }

            .e-main-visual-pc-date {
              letter-spacing: 0.1em;
              padding: 0 0 ${rem(12.5)} 0;
            }

            .e-main-visual-pc-subject {
              border-top: ${rem(1)} solid #fff;
              padding: ${rem(16.5)} 0 0 0;
              letter-spacing: 0.45em;
            }
          }

          > .e-main-visual-illust {
            position: absolute;
            top: ${rem(343 / 2)};
            left: ${rem(40 / 2)};
            width: calc(300 / 750 * 100%);
            aspect-ratio: 1/1;

            @media ${breakpoints.lg} {
              top: ${rem(196)};
              left: ${rem(512)};
              width: ${rem(200)};
            }

            > .e-main-visual-illust-contents {
              position: relative;
              width: 100%;
              aspect-ratio: 1/1;

              > .e-main-visual-fusen1 {
                position: absolute;
                top: calc(240 / 300 * 100%);
                left: calc(5 / 300 * 100%);
                width: calc(52 / 300 * 100%);
                opacity: 0;
                transform: translateY(${rem(10)});
                animation: slideFadeIn 1s 3.4s forwards;

                @media ${breakpoints.lg} {
                  top: ${rem(202)};
                  left: ${rem(12)};
                  width: ${rem(41)};
                }
              }
              > .e-main-visual-fusen2 {
                position: absolute;
                top: calc(224 / 300 * 100%);
                left: calc(78 / 300 * 100%);
                width: calc(56 / 300 * 100%);
                opacity: 0;
                transform: translateY(${rem(10)});
                animation: slideFadeIn 1s 3.6s forwards;

                @media ${breakpoints.lg} {
                  top: ${rem(200)};
                  left: ${rem(70)};
                  width: ${rem(44)};
                }
              }
              > .e-main-visual-fusen3 {
                position: absolute;
                top: calc(174 / 300 * 100%);
                left: calc(32 / 300 * 100%);
                width: calc(46.5 / 300 * 100%);
                opacity: 0;
                transform: translateY(${rem(10)});
                animation: slideFadeIn 1s 3.8s forwards;

                @media ${breakpoints.lg} {
                  top: ${rem(160)};
                  left: ${rem(53)};
                  width: ${rem(37)};
                }
              }
              > .e-main-visual-compas {
                position: absolute;
                top: calc(167 / 300 * 100%);
                left: calc(187.4 / 300 * 100%);
                width: calc(106.5 / 300 * 100%);

                opacity: 0;
                transform: translateY(${rem(10)});
                animation: slideFadeIn 1s 4s forwards;

                @media ${breakpoints.lg} {
                  top: ${rem(105)};
                  left: ${rem(154)};
                  width: ${rem(84)};
                }
              }

              > .e-main-visual-note {
                position: absolute;
                top: ${rem(27 / 2)};
                left: ${rem(49 / 2)};
                width: ${rem(207 / 2)};
                opacity: 0;
                transform: translateY(${rem(10)});
                animation: slideFadeIn 1s 4.2s forwards;

                top: calc(27 / 300 * 100%);
                left: calc(49 / 300 * 100%);
                width: calc(207 / 300 * 100%);

                @media ${breakpoints.lg} {
                  top: ${rem(23)};
                  left: ${rem(45)};
                  width: ${rem(163)};
                }
              }

              > .e-main-visual-pen {
                position: absolute;
                top: calc(30 / 300 * 100%);
                left: calc(16 / 300 * 100%);
                width: calc(92 / 300 * 100%);
                opacity: 0;
                transform: translateY(${rem(10)});
                animation: slideFadeIn 1s 4.4s forwards;

                @media ${breakpoints.lg} {
                  top: ${rem(12)};
                  left: ${rem(31)};
                  width: ${rem(70)};
                }
              }
            }
          }
        }
      }
    }

    > .e-main-under {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      padding: ${rem(60 / 2)} 0 ${rem(50 / 2)} 0;

      @media ${breakpoints.lg} {
        flex-direction: row;
        padding: ${rem(50)} 0;
      }

      > .e-main-achievements {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        > .e-main-achievements-contents {
          display: flex;
          justify-content: center;
          gap: ${rem(37 / 2)};

          @media ${breakpoints.lg} {
            gap: ${rem(26.8)};
          }

          > .e-main-achievements-image {
            width: ${rem(305.5 / 2)};

            @media ${breakpoints.lg} {
              width: ${rem(191.6)};
            }
          }
        }

        > .e-main-achievements-text {
          margin: ${rem(18 / 2)} 0 0 0;
          font-size: ${rem(20 / 2)};

          @media ${breakpoints.lg} {
            margin: ${rem(17)} 0 0 0;
            font-size: ${rem(14)};
          }
        }
      }

      > .e-main-logo {
        width: ${rem(413 / 2)};
        margin: ${rem(17 / 2)} 0 0 0;

        @media ${breakpoints.lg} {
          width: ${rem(285)};
          margin: 0 0 ${rem(50)} ${rem(48.5)};
        }
      }
    }
  }

  .is-sp {
    display: block;

    @media ${breakpoints.lg} {
      display: none;
    }
  }

  .is-pc {
    display: none;

    @media ${breakpoints.lg} {
      display: block;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes slideFadeIn {
    0% {
      opacity: 0;
      transform: translateY(${rem(10)});
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

export const MainVisualSection: (props: MainVisualSectionProps) => JSX.Element = StyledComponent

export default MainVisualSection
