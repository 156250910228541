import React from 'react'
import styled from 'styled-components'
import { rem, breakpoints, fontFamily } from 'variables/_index'
import { StaticImage } from 'gatsby-plugin-image'
import { useInView } from 'react-intersection-observer'

export interface AboutSectionProps {
  readonly noProps?: string
}

type AboutSectionPropsClassName = AboutSectionProps & {
  readonly className: string
}

function Component({ className }: AboutSectionPropsClassName) {
  const { ref, inView } = useInView({
    rootMargin: '-120px', // ref要素が現れてから120px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  })

  const { ref: movieRef, inView: movieInView } = useInView({
    rootMargin: '-100px',
    triggerOnce: true,
  })
  return (
    <div className={className}>
      <div className="e-inner">
        <div className="e-contents">
          <h2 className="e-about-head" ref={ref} data-anim={inView}>
            <span className="e-about-head-dp">
              <StaticImage src="../../../assets/images/spring/head-dp.png" alt="" />
            </span>
            <span className="e-about-head-background">点数アップできる</span>
            <span className="e-about-head-background">
              <span className="font-mont">2</span>つの理由
            </span>
            <span className="e-about-head-arrow">
              <StaticImage src="../../../assets/images/spring/head-arrow.png" alt="" />
            </span>
          </h2>

          <div className="e-about-contents1">
            <div className="e-about-contents-head-bubble">
              <p className="e-about-contents-head-bubble-text">
                理由<span className="font-mont">1</span>
              </p>
            </div>
            <h3 className="e-about-contents-head">
              講師が
              <span className="is-dot">直</span>
              <span className="is-dot">接</span>
              教える
              <br />
              <span className="is-big">オンライン個別指導</span>
            </h3>

            <div className="e-about-contents">
              <div className="e-about-movie-area">
                <p className="e-about-movie-area-head">実際の授業の様子</p>

                <div className="e-about-movie" ref={movieRef} data-anim={movieInView}>
                  <div className="e-about-movie-area-illust1">
                    <StaticImage src="../../../assets/images/spring/about/ill_compas.png" alt="" />
                  </div>
                  <iframe
                    src="https://www.youtube-nocookie.com/embed/BNdjdVvzivg?si=JdwjoDCBNSiltfUt"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                  <div className="e-about-movie-area-illust2">
                    <StaticImage src="../../../assets/images/spring/about/ill_noteruler.png" alt="" />
                  </div>
                </div>
              </div>
              <div className="e-about-contents-inner">
                <div className="e-about-item">
                  <div>
                    <div className="e-about-item-head">
                      <span className="">
                        お子さまの
                        <br />
                        理解度に合わせた指導
                      </span>
                    </div>

                    <p className="e-about-item-text">
                      わからない問題は繰り返し解説するなど、<span className="is-highlight">お子さまの理解状況を確認しながら講師が柔軟に指導</span>
                      。質問は講師に個別に聞けるので、その場で苦手を「わかった！」に変えることができます。
                    </p>
                  </div>
                </div>

                <div className="e-about-item-illust">
                  <StaticImage src="../../../assets/images/spring/about/ill_pencase.png" alt="" />
                </div>

                <div className="e-about-item">
                  <div>
                    <div className="e-about-item-head">
                      <span className="">
                        わかりやすく
                        <br />
                        丁寧な解説
                      </span>
                    </div>

                    <div className="e-about-item-image" data-type="is-sp">
                      <StaticImage src="../../../assets/images/spring/about/about1_photo2.png" alt="" />
                    </div>

                    <p className="e-about-item-text">
                      解説は、PC画面にテキストを映して書き込みながら行うので<span className="is-highlight">見やすく、わかりやすい</span>
                      。わかるまで、1対1で丁寧に解説します。
                    </p>
                  </div>

                  <div className="e-about-item-image" data-type="is-pc">
                    <StaticImage src="../../../assets/images/spring/about/about1_photo2.png" alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="e-about-contents-head-bubble" data-number="second">
              <p className="e-about-contents-head-bubble-text">
                理由<span className="font-mont">2</span>
              </p>
            </div>
            <h3 className="e-about-contents-head">
              “わからない”を
              <span className="is-dot">克</span>
              <span className="is-dot">服</span>
              できる
              <br />
              <span className="is-big">総復習</span>
            </h3>

            <div className="e-about-contents">
              <div className="e-about-contents-inner">
                <div className="e-about-item" data-type="flex-reverse">
                  <div className="e-about-item-image" data-type="point2">
                    <StaticImage src="../../../assets/images/spring/about/about2_photo1.png" alt="" />
                  </div>

                  <p className="e-about-item-text" data-type="point2">
                    春期講習では、<span className="is-highlight">中学入学時から今までの学習範囲を総復習！</span>
                    苦手を見つけ克服することで、基礎学力が身につきます。その結果、
                    <span className="is-highlight">新学年の学校の授業が理解しやすくなり、テスト点数がアップ！</span>
                    <span className="e-about-item-notes">&#8251;学年によって講習の内容が異なります。</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<AboutSectionPropsClassName>`
  background-color: #ddf3ff;

  .e-inner {
    width: ${rem(670 / 2)};
    margin: 0 auto;

    @media ${breakpoints.lg} {
      width: ${rem(1000)};
    }

    > .e-contents {
      padding: ${rem(150 / 2)} 0 ${rem(100 / 2)} 0;

      @media ${breakpoints.lg} {
        padding: ${rem(160)} 0 ${rem(100)} 0;
      }

      > .e-about-head {
        width: fit-content;
        margin: 0 auto;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: ${rem(10 / 2)};
        align-items: center;
        font-family: ${fontFamily.zenKaku};
        font-weight: bold;
        font-size: ${rem(62 / 2)};
        color: #0028be;

        @media ${breakpoints.lg} {
          gap: ${rem(10)};
          font-size: ${rem(52)};
        }

        &[data-anim='true'] {
          > .e-about-head-background,
          .e-about-head-dp {
            animation: slideUp 0.8s 0s forwards;
          }

          > .e-about-head-arrow {
            animation: arrowSlide 1.5s 0.4s forwards;
          }
        }

        @keyframes slideUp {
          0% {
            transform: translateY(${rem(25)});
            opacity: 0;
          }
          100% {
            transform: translateY(${rem(0)});
            opacity: 1;
          }
        }

        > .e-about-head-background {
          width: fit-content;
          display: block;
          background-color: #ffffff;
          border-radius: ${rem(10)};
          padding: ${rem(8 / 2)} ${rem(20 / 2)};
          z-index: 1;
          transform: translateY(${rem(25)});
          opacity: 0;

          @media ${breakpoints.lg} {
            padding: ${rem(4)} ${rem(20)};
          }
        }

        > .e-about-head-dp {
          position: absolute;
          left: ${rem(-28 / 2)};
          top: ${rem(-10 / 2)};
          width: ${rem(40 / 2)};
          z-index: 2;
          transform: translateY(${rem(25)});
          opacity: 0;

          @media ${breakpoints.lg} {
            left: ${rem(-20)};
            top: ${rem(-10)};
            width: ${rem(34)};
          }
        }

        > .e-about-head-arrow {
          position: absolute;
          width: ${rem(200)};
          right: ${rem(-65)};
          top: ${rem(10)};
          rotate: -45deg;
          opacity: 0;
          transform: translateX(${rem(-50)});

          @media ${breakpoints.lg} {
            width: ${rem(330)};
            right: ${rem(-100)};
            top: ${rem(15)};
          }

          @keyframes arrowSlide {
            0% {
              opacity: 0;

              transform: translateX(${rem(-50)});
            }
            100% {
              transform: translateX(${rem(0)});
              opacity: 1;
            }
          }
        }
      }

      > .e-about-contents1 {
        margin: ${rem(87.5 / 2)} auto 0 auto;

        @media ${breakpoints.lg} {
          margin: ${rem(84)} auto 0 auto;
        }
      }

      .e-about-contents {
        margin: ${rem(20 / 2)} auto 0 auto;
        background-color: #ffffff;
        border: #ff1a56 solid ${rem(2)};
        border-radius: ${rem(20 / 2)};

        @media ${breakpoints.lg} {
          border: #ff1a56 solid ${rem(3)};
          margin: ${rem(20)} auto 0 auto;
        }

        .e-about-contents-inner {
          width: ${rem(560 / 2)};
          margin: 0 auto;
          padding: ${rem(55 / 2)} 0 ${rem(60 / 2)} 0;

          @media ${breakpoints.lg} {
            width: ${rem(850)};
            padding: ${rem(46)} 0 ${rem(50)} 0;
          }
        }
      }

      .e-about-contents-head-bubble {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: ${rem(250 / 2)};
        height: ${rem(80 / 2)};
        margin: 0 auto;
        background-color: #ff648c;
        border-radius: 9999px;

        @media ${breakpoints.lg} {
          width: ${rem(200)};
          height: ${rem(64.25)};
        }

        &::after {
          position: absolute;
          top: 100%;
          display: block;
          content: '';
          height: calc(${rem(43 / 2)} / 2 * tan(30deg));
          width: ${rem(43 / 2)};
          clip-path: polygon(50% 0, 100% 100%, 0 100%);
          background-color: #ff648c;
          transform: rotate(180deg);

          @media ${breakpoints.lg} {
            height: calc(${rem(37.5)} / 2 * tan(35deg));
            width: ${rem(37.5)};
          }
        }

        &[data-number='second'] {
          margin: ${rem(100 / 2)} auto 0 auto;
        }

        > .e-about-contents-head-bubble-text {
          text-align: center;
          font-size: ${rem(32 / 2)};
          color: #ffffff;
          margin: 0;
          font-family: ${fontFamily.zenKaku};
          font-weight: bold;

          @media ${breakpoints.lg} {
            font-size: ${rem(26)};
          }
        }
      }

      .e-about-contents-head {
        text-align: center;
        font-size: ${rem(42 / 2)};
        color: #0028be;
        font-weight: bold;
        font-family: ${fontFamily.zenKaku};
        margin: ${rem(43 / 2)} auto 0 auto;
        letter-spacing: 0.06em;

        @media ${breakpoints.lg} {
          font-size: ${rem(32)};
          margin: ${rem(32)} auto 0 auto;
        }

        > .is-big {
          display: block;
          margin-top: ${rem(16 / 2)};
          font-size: ${rem(54 / 2)};

          @media ${breakpoints.lg} {
            margin-top: ${rem(12)};
            font-size: ${rem(42)};
          }
        }

        > .is-dot {
          position: relative;

          &::before {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);

            display: block;
            content: '';
            width: ${rem(9 / 2)};
            height: ${rem(9 / 2)};
            border-radius: 9999px;
            background-color: #0028be;
          }
        }
      }

      .e-about-movie-area {
        padding: ${rem(51.6 / 2)} 0 ${rem(60 / 2)} 0;
        border-bottom: #ff1a56 solid ${rem(2)};

        @media ${breakpoints.lg} {
          padding: ${rem(43.2)} 0 ${rem(53.1)} 0;
          border-bottom: #ff1a56 solid ${rem(3)};
        }

        .e-about-movie-area-head {
          margin: 0;
          text-align: center;
          font-size: ${rem(32 / 2)};
          color: #000000;
          font-weight: bold;
          font-family: ${fontFamily.zenKaku};
          letter-spacing: 0.05em;

          @media ${breakpoints.lg} {
            font-size: ${rem(24)};
          }
        }

        .e-about-movie {
          position: relative;
          max-width: ${rem(560 / 2)};
          margin: ${rem(20 / 2)} auto 0 auto;

          @media ${breakpoints.lg} {
            max-width: ${rem(500)};
            margin: ${rem(17)} auto 0 auto;
          }

          iframe {
            border: none;
            width: 100%;
            margin: 0;
            aspect-ratio: 560 / 350;
          }

          &[data-anim='true'] {
            > .e-about-movie-area-illust1 {
              animation: slideFadeIn 1s 0s forwards;
            }

            > .e-about-movie-area-illust2 {
              animation: slideFadeIn 1s 0s forwards;
            }
          }
          .e-about-movie-area-illust1 {
            display: none;

            @media ${breakpoints.lg} {
              width: ${rem(100)};
              display: block;
              position: absolute;
              top: ${rem(120)};
              left: ${rem(-154)};
              opacity: 0;
              transform: translateY(${rem(20)});
            }
          }
          .e-about-movie-area-illust2 {
            display: none;

            @media ${breakpoints.lg} {
              width: ${rem(148.73)};
              display: block;
              position: absolute;
              top: ${rem(50)};
              right: ${rem(-180)};
              opacity: 0;
              transform: translateY(${rem(20)});
            }
          }
        }
      }

      .e-about-item {
        display: flex;
        flex-direction: column;

        @media ${breakpoints.lg} {
          flex-direction: row;
        }

        &[data-type='flex-reverse'] {
          @media ${breakpoints.lg} {
            flex-direction: row-reverse;
          }
        }

        .e-about-item-head {
          text-align: center;
          position: relative;

          text-decoration: underline;
          text-underline-offset: ${rem(10 / 2)};
          text-decoration-thickness: ${rem(2)};
          text-decoration-color: #ff1a56;

          color: #000;
          font-size: ${rem(42 / 2)};
          font-family: ${fontFamily.zenKaku};
          font-weight: bold;
          letter-spacing: 0.06em;

          @media ${breakpoints.lg} {
            text-align: left;
            font-size: ${rem(30)};
            text-underline-offset: ${rem(8)};
            text-decoration-thickness: ${rem(3)};

            max-height: ${rem(100)};
          }
        }

        .e-about-item-image {
          width: ${rem(490 / 2)};
          margin: 0 auto;
          position: relative;
          margin: ${rem(54 / 2)} auto 0 auto;
          flex-shrink: 0;

          @media ${breakpoints.lg} {
            width: ${rem(380)};
            margin: 0 0 0 ${rem(30)};
          }

          &[data-type='point2'] {
            margin: 0 auto;

            @media ${breakpoints.lg} {
              margin: 0 0 0 ${rem(30)};
            }
          }

          &[data-type='is-sp'] {
            display: block;

            @media ${breakpoints.lg} {
              display: none;
            }
          }

          &[data-type='is-pc'] {
            display: none;

            @media ${breakpoints.lg} {
              display: block;
            }
          }
        }

        .e-about-item-text {
          color: #000;
          position: relative;
          margin: ${rem(42.6 / 2)} auto 0 auto;
          font-size: ${rem(32 / 2)};
          line-height: calc(58 / 32);
          letter-spacing: 0.06em;

          @media ${breakpoints.lg} {
            margin: ${rem(24)} auto 0 auto;
            font-size: ${rem(18)};
            line-height: calc(32 / 18);
          }

          > .is-highlight {
            color: #ff1a56;
          }

          &[data-type='point2'] {
            @media ${breakpoints.lg} {
              margin: ${rem(60)} auto 0 auto;
            }
          }

          .e-about-item-notes {
            display: block;
            margin-top: ${rem(16 / 2)};
            font-size: ${rem(22 / 2)};

            @media ${breakpoints.lg} {
              margin-top: ${rem(34)};
              font-size: ${rem(14)};
            }
          }
        }
      }

      .e-about-item-illust {
        width: ${rem(75 / 2)};
        margin: ${rem(16 / 2)} auto ${rem(40 / 2)} auto;

        @media ${breakpoints.lg} {
          width: ${rem(50)};
          margin: ${rem(35)} auto ${rem(18)} auto;
        }
      }
    }
  }

  .font-mont {
    font-family: ${fontFamily.montserrat};
  }

  @keyframes slideFadeIn {
    0% {
      opacity: 0;
      transform: translateY(${rem(20)});
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

export const AboutSection: (props: AboutSectionProps) => JSX.Element = StyledComponent

export default AboutSection
