import { StaticImage } from 'gatsby-plugin-image'
import React, { useState } from 'react'
import styled from 'styled-components'
import { rem, breakpoints, innerSpringWidth, fontFamily, springColor } from 'variables/_index'
import SectionHeading from './atom/sectionHeading'
import { useInView } from 'react-intersection-observer'

export interface FlowSectionProps {
  readonly noProps?: string
}

type FlowSectionPropsClassName = FlowSectionProps & {
  readonly className: string
}

function Component({ className }: FlowSectionPropsClassName) {
  const [open, setOpen] = useState<boolean>(false)
  const toggleOpen = () => {
    setOpen(!open)
  }

  const { ref, inView } = useInView({
    rootMargin: '-100px', // ref要素が現れてから100px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  })

  return (
    <div className={className}>
      <h2 className="e-flow-head">
        <span className="e-flow-head-jp">講習までの流れ</span>
        <span className="e-flow-head-en">flow</span>
      </h2>

      <div ref={ref} className="e-flow-content" data-anim={inView}>
        <div className="e-inner">
          <div className="e-flow-image">
            <StaticImage src="../../../assets/images/spring/flow/flow_top_img.png" alt="" placeholder="none" />
          </div>
          <div className="e-flow-box">
            <h3 className="e-flow-title">
              <span className="e-flow-number">1</span>
              <span className="e-flow-text">無料体験のお申し込み</span>
            </h3>
          </div>
          <div className="e-flow-arrow">
            <StaticImage src="../../../assets/images/spring/flow/flow_arrow.svg" alt="" placeholder="none" />
          </div>
          <div className="e-flow-box">
            <h3 className="e-flow-title">
              <span className="e-flow-number">2</span>
              <span className="e-flow-text">体験ガイダンス日時の設定</span>
            </h3>
          </div>
          <div className="e-flow-arrow">
            <StaticImage src="../../../assets/images/spring/flow/flow_arrow.svg" alt="" placeholder="none" />
          </div>
          <div className="e-flow-box">
            <h3 className="e-flow-title">
              <span className="e-flow-number">3</span>
              <span className="e-flow-text">
                体験ガイダンス<small>（スマホで参加OK!）</small>
              </span>
            </h3>

            <button type="button" onClick={toggleOpen} className="e-flow-open">
              {open ? (
                <StaticImage src="../../../assets/images/spring/flow/flow-minus.png" alt="" placeholder="none" />
              ) : (
                <StaticImage src="../../../assets/images/spring/flow/flow-plus.png" alt="" placeholder="none" />
              )}
            </button>

            <div className="e-flow-boxContent3" data-open={open}>
              <div className="e-flow-box-image">
                <StaticImage src="../../../assets/images/spring/flow/flow_step3.png" alt="" placeholder="none" />
              </div>
              <div className="e-flow-boxTexts">
                <div className="e-flow-boxText c-text-box">
                  <p className="c-text-box__text">
                    お子さまの学習状況や
                    <br className="u-hidden-pc" />
                    お悩みをヒアリング
                  </p>
                </div>
                <div className="e-flow-boxLine">
                  <StaticImage src="../../../assets/images/spring/flow/flow-dotted.png" alt="" placeholder="none" />
                </div>
                <div className="e-flow-boxText c-text-box">
                  <p className="c-text-box__text">
                    Fitのサービスや講習についての
                    <br className="u-hidden-pc" />
                    詳しいご説明と質疑応答
                  </p>
                </div>
                <div className="e-flow-boxLine">
                  <StaticImage src="../../../assets/images/spring/flow/flow-dotted.png" alt="" placeholder="none" />
                </div>
                <div className="c-text-box is-large">
                  <div className="c-text-box__head">講習のお申し込み</div>
                  <p className="c-text-box__text">
                    担当者と一緒に、お子さまに
                    <br className="u-hidden-pc" />
                    適切な受講プランを決めます。
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="e-flow-arrow">
            <StaticImage src="../../../assets/images/spring/flow/flow_arrow.svg" alt="" placeholder="none" />
          </div>
          <div className="e-flow-box">
            <h3 className="e-flow-title">
              <span className="e-flow-number">4</span>
              <span className="e-flow-text">春期講習スタート!</span>
            </h3>
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<FlowSectionPropsClassName>`
  margin-left: auto;
  margin-right: auto;
  padding-top: ${rem(100 / 2)};

  @media ${breakpoints.lg} {
    padding-top: ${rem(100)};
  }

  .c-text-box {
    background-color: #fff0f0;
    text-align: center;
    border-radius: ${rem(20 / 2)};
    padding: ${rem(20 / 2)} ${rem(40 / 2)};

    @media ${breakpoints.lg} {
      border-radius: ${rem(60)};
      padding: ${rem(16)} ${rem(30)};
    }

    &.is-large {
      padding-top: ${rem(30 / 2)};
      padding-bottom: ${rem(34 / 2)};
    }

    .c-text-box__head {
      color: ${springColor.accent2};
      font-size: ${rem(36 / 2)};
      text-align: center;
      letter-spacing: 0.1em;
      margin-bottom: ${rem(10 / 2)};

      @media ${breakpoints.lg} {
        font-size: ${rem(24)};
        margin-bottom: ${rem(8)};
      }
    }

    .c-text-box__text {
      font-size: ${rem(28 / 2)};
      line-height: 1.42;
      letter-spacing: 0.1em;
      margin: 0;

      @media ${breakpoints.lg} {
        font-size: ${rem(18)};
      }
    }
  }

  > .e-flow-head {
    text-align: center;
    display: flex;
    flex-direction: column;

    > .e-flow-head-jp {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: ${rem(68 / 2)};
      font-family: ${fontFamily.zenKaku};
      color: #0028be;

      @media ${breakpoints.lg} {
        font-size: ${rem(54)};
        font-weight: bold;
      }

      &::after {
        display: block;
        content: '';
        width: ${rem(12 / 2)};
        height: ${rem(12 / 2)};
        margin-top: ${rem(12 / 2)};
        border-radius: ${rem(3)};
        background-color: #ff1a56;

        @media ${breakpoints.lg} {
          width: ${rem(10)};
          height: ${rem(10)};
          margin-top: ${rem(10)};
          border-radius: ${rem(5)};
        }
      }
    }

    > .e-flow-head-en {
      font-family: ${fontFamily.montserrat};
      color: #acacac;
      font-size: ${rem(38 / 2)};
      margin-top: ${rem(15 / 2)};

      @media ${breakpoints.lg} {
        margin-top: ${rem(11.8)};
        font-size: ${rem(30)};
        font-weight: 600;
        letter-spacing: 0.2em;
      }
    }
  }

  > .e-flow-content {
    background-color: ${springColor.fitMainBackground};
    margin: ${rem(50 / 2)} 0 0 0;
    padding: ${rem(60 / 2)} 0 ${rem(100 / 2)};

    @media ${breakpoints.lg} {
      margin: ${rem(50)} 0 0 0;
      padding: ${rem(60)} 0 ${rem(80)};
    }

    &[data-anim='true'] {
      > .e-inner {
        > .e-flow-image {
          animation: slideFadeIn 1s 0s forwards;
        }
      }
    }

    > .e-inner {
      width: ${innerSpringWidth.sm};
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;

      @media ${breakpoints.lg} {
        width: ${rem(1080)};
        padding-left: ${rem(40)};
        padding-right: ${rem(40)};
      }

      > .e-flow-image {
        width: ${rem(364 / 2)};
        margin-bottom: ${rem(64 / 2)};
        margin-left: auto;
        margin-right: auto;

        opacity: 0;
        transform: translateY(${rem(20)});

        @media ${breakpoints.lg} {
          width: ${rem(264)};
          margin-bottom: ${rem(40)};
        }

        img {
          margin: 0 auto;
          max-width: 100%;
        }
      }

      > .e-flow-box {
        position: relative;
        margin-left: ${rem(-10 / 2)};
        margin-right: ${rem(-10 / 2)};
        border: 2px solid ${springColor.fitMain};
        border-radius: ${rem(20 / 2)};
        background-color: #fff;

        @media ${breakpoints.lg} {
          width: ${rem(800)};
          margin: 0 auto;
          border-radius: ${rem(20)};
          border-width: 3px;
        }

        &.is-wide {
          padding-top: ${rem(24 / 2)};

          @media ${breakpoints.lg} {
            padding-top: ${rem(12)};
          }
        }

        > .e-flow-title {
          position: relative;
          color: ${springColor.text};
          font-weight: bold;
          font-family: ${fontFamily.zenKaku};
          display: flex;
          align-items: center;
          padding: 0 ${rem(24 / 2)} 0 ${rem(20 / 2)};
          margin: 0;
          min-height: ${rem(100 / 2)};

          @media ${breakpoints.lg} {
            padding: 0 ${rem(27.8)};
            min-height: ${rem(74)};
          }

          &::after {
            position: absolute;
            display: block;
            content: '';
            width: ${rem(31 / 2)};
            left: ${rem(-(31 / 2) / 2)};
            height: ${rem(6 / 2)};

            border-radius: 9999px;
            background-color: ${springColor.fitMain};

            @media ${breakpoints.lg} {
              left: ${rem(-13.21)};
              width: ${rem(26.42)};
              height: ${rem(6)};
            }
          }

          > .e-flow-number {
            font-family: ${fontFamily.montserrat};
            font-size: ${rem(64 / 2)};
            color: ${springColor.fitMain};
            line-height: 1;
            position: relative;

            @media ${breakpoints.lg} {
              font-size: ${rem(44)};
            }
          }

          > .e-flow-text {
            color: #000;
            margin-left: ${rem(16 / 2)};
            font-size: ${rem(38 / 2)};
            letter-spacing: 0.1em;

            @media ${breakpoints.lg} {
              margin-left: ${rem(20)};
              font-size: ${rem(30)};
            }

            > small {
              font-size: ${rem(24 / 2)};
              letter-spacing: 0;
              font-weight: normal;

              @media ${breakpoints.lg} {
                font-size: ${rem(20)};
              }
            }
          }
        }

        > .e-flow-open {
          position: absolute;
          width: ${rem(45 / 2)};
          top: ${rem(29 / 2)};
          right: ${rem(14 / 2)};
          border: none;
          cursor: pointer;
          outline: none;
          padding: 0;
          appearance: none;
          background-color: transparent;

          @media ${breakpoints.lg} {
            width: ${rem(36)};
            top: ${rem(20)};
            right: ${rem(20)};
          }
        }

        > .e-flow-boxContent3 {
          margin: ${rem(32 / 2)} auto ${rem(40 / 2)};
          padding: 0 ${rem(60 / 2)};
          transition: 0.5s;
          overflow: hidden;
          @media ${breakpoints.lg} {
            margin: ${rem(24)} auto ${rem(40)};
            padding: 0 ${rem(100)};
          }

          &[data-open='false'] {
            max-height: 0;
            margin: 0;
          }
          > .e-flow-box-image {
            text-align: center;

            img {
              width: ${rem(550 / 2)};

              @media ${breakpoints.lg} {
                width: ${rem(400)};
              }
            }
          }

          > .e-flow-boxTexts {
            margin-top: ${rem(60 / 2)};

            > .e-flow-boxText {
            }
          }

          .e-flow-boxLine {
            width: ${rem(4 / 2)};
            margin: ${rem(3 / 2)} auto;

            @media ${breakpoints.lg} {
              width: ${rem(4)};
              margin: ${rem(3)} auto;
            }
          }
        }
      }

      > .e-flow-arrow {
        width: ${rem(63.554 / 2)};
        height: ${rem(37.373 / 2)};
        margin: ${rem(28.5 / 2)} auto ${rem(24.1 / 2)} auto;

        @media ${breakpoints.lg} {
          width: ${rem(63.554)};
          height: ${rem(37.373)};
          margin-top: ${rem(18.6)};
          margin-bottom: ${rem(19.1)};
        }
      }
    }

    @keyframes slideFadeIn {
      0% {
        opacity: 0;
        transform: translateY(${rem(20)});
      }

      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
`

export const FlowSection: (props: FlowSectionProps) => JSX.Element = StyledComponent

export default FlowSection
